import styled from "styled-components"

export const Container = styled.div`
    width: 100%;
    min-height: 100vh;
    background: var(--primary-white-text);
`

export const OutletContainer = styled.div`
    width: 100%;
    min-height: calc(100vh - 122px);
`