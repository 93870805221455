import { ChangeEvent, useState } from "react";
// import { Radio } from "../../../atoms/Radio/Radio";
// import { useCreateProductStore } from "../../../stores/CreateProduct/CreateProductStore";
import { Container, Content, HeroTitle } from "../CreateProduct.styles";
import { StyledButton, StyledInput } from "./CreateProductName.styles";

type Props = {
  onClickGenerate: (brandName: string) => void;
};

export const CreateProductNameStep = ({ onClickGenerate }: Props) => {
  //   const { productNameSuggestions } = useCreateProductStore();

  const [selectedNameOption, setSelectedNameOption] = useState<string>("");
  const [selectedNameInput, setSelectNameInput] = useState<string>("");

  //   const onClickNameOption = (value: string) => {
  //     // Reset selections
  //     if (selectedNameOption === value) {
  //       setSelectedNameOption("");
  //       return;
  //     }

  //     setSelectedNameOption(value);
  //     setSelectNameInput("");
  //   };

  const onNameInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSelectedNameOption("");
    setSelectNameInput(e.target.value);
  };

  const onClickChooseName = () => {
    if (!selectedNameInput && !selectedNameOption) return;

    onClickGenerate(selectedNameInput || selectedNameOption);
  };

  //   const generateNameOptions = productNameSuggestions.map((name) => (
  //     <Radio
  //       key={name}
  //       value={name}
  //       isActive={name === selectedNameOption}
  //       text={name}
  //       onClick={onClickNameOption}
  //     />
  //   ));

  return (
    <Container>
      <Content>
        <HeroTitle>CHOOSE A NAME FOR YOUR BRAND</HeroTitle>
        {/* <NameOptionTitle>Name ideas</NameOptionTitle>
                <NameOptionContainer>
                    { generateNameOptions }
                </NameOptionContainer> */}

        <StyledInput
          value={selectedNameInput}
          onChange={(e) => onNameInputChange(e)}
          label="Enter your Brand Name"
          placeholder="e.g. ProProjector"
        />

        <StyledButton onClick={onClickChooseName}>Generate</StyledButton>
      </Content>
    </Container>
  );
};
