import { ChangeEvent } from "react"
import { Container, StyledInput, StyledLabel, StyledSubLabel } from "./Input.styles"

type Props = {
    value: string
    placeholder?: string
    label?: string
    sublabel?: string
    type?: "text" | "email" | "password"
    className?: string
    hasError?: boolean

    onChange: (e: ChangeEvent<HTMLInputElement>) => void
    onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void
}

export const Input = ({ value, placeholder, label, sublabel, type = "text", onChange, className, onKeyDown, hasError = false }: Props) => {
    return (
        <Container className={className}>
            {label && <StyledLabel>{label}</StyledLabel>}
            {sublabel && <StyledSubLabel>{sublabel}</StyledSubLabel>}
            <StyledInput
                value={value}
                placeholder={placeholder}
                type={type}
                onChange={(e) => onChange(e)}
                data-haserror={hasError}
                onKeyDown={onKeyDown}
            />
        </Container>
    )
}