type Props = {
    readonly size?: string;
    readonly color?: string;
};

export const Dollar = ({ size = "24", color = "var(--brand-color-1)" }: Props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500" width={size} height={size}>
            <path
                d="m 145,312 c -2,69 31,100 104,102 78,1 113,-34 109,-101 -6,-58 -62,-73 -106,-79 -48,-17 -99,-25 -99,-95 0,-48 32,-79 99,-78 60,0 97,25 96,84"
                style={{ fill: 'none', stroke: color, strokeWidth: 40 }}
            />
            <path d="m 250,15 0,470" style={{ stroke: color, strokeWidth: 30 }} />
        </svg>
    );
};
