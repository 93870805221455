import React, { useState, useEffect } from "react";
import {
  CampaignDropdownContainer,
  DropdownButton,
  CheckboxContainer,
  CheckboxLabel,
  ChevronContainer,
  DropdownWrapper,
  ToggleAllButton,
  HiddenCheckbox,
} from "./CampaignSelection.styles";
import { Chevron } from "../../icons/Chevron";

interface CampaignSelectionProps {
  selectedCampaigns: string[];
  handleCampaignChange: (updatedClickedCampaigns: string[]) => void; // Adjusted to accept an array directly
  allCampaigns: string[];
}

const CampaignSelection: React.FC<CampaignSelectionProps> = ({
  selectedCampaigns,
  handleCampaignChange,
  allCampaigns,
}) => {
  const [showCampaignSelection, setShowCampaignSelection] = useState(false);
  const [selectAll, setSelectAll] = useState(false);

  // Adjust the effect to monitor changes in selectedCampaigns from the parent component
  useEffect(() => {
    setSelectAll(selectedCampaigns.length === allCampaigns.length);
  }, [selectedCampaigns, allCampaigns]);

  const toggleSelectAll = () => {
    const updatedCampaigns = selectAll ? [] : [...allCampaigns];
    handleCampaignChange(updatedCampaigns); // Directly handle the update in the parent component
  };

  const handleCampaignClick = (campaign: string) => {
    const updatedClickedCampaigns = selectedCampaigns.includes(campaign)
      ? selectedCampaigns.filter((c) => c !== campaign)
      : [...selectedCampaigns, campaign];

    handleCampaignChange(updatedClickedCampaigns); // Directly handle the update in the parent component
  };

  return (
    <CampaignDropdownContainer>
      <DropdownButton
        onClick={() => setShowCampaignSelection(!showCampaignSelection)}
      >
        Campaigns
        <ChevronContainer data-open={showCampaignSelection.toString()}>
          <Chevron size="20" color="var(--brand-color-1)" />
        </ChevronContainer>
      </DropdownButton>
      {showCampaignSelection && (
        <DropdownWrapper>
          <CheckboxContainer>
            {allCampaigns.map((campaign) => (
              <CheckboxLabel key={campaign}>
                <input
                  type="checkbox"
                  id={campaign}
                  checked={selectedCampaigns.includes(campaign)}
                  onChange={() => handleCampaignClick(campaign)}
                />
                {campaign}
              </CheckboxLabel>
            ))}
          </CheckboxContainer>
          <ToggleAllButton
            key="select-all"
            style={{ padding: "10px", marginTop: "20px" }}
            htmlFor="toggle-all-checkbox" // Ensure this ID matches the checkbox's ID
          >
            Toggle All
            <HiddenCheckbox
              id="toggle-all-checkbox"
              checked={selectAll}
              onChange={toggleSelectAll}
            />
          </ToggleAllButton>
        </DropdownWrapper>
      )}
    </CampaignDropdownContainer>
  );
};

export default CampaignSelection;
