import { AetherService } from "../../services/AetherSerivce";
import { UserMeResponse } from "../../types/User_T";

export const checkIsAuth = async (set: Function) => {
  try {
    const { data } = await AetherService.get<UserMeResponse>(
      "/user/me/details"
    );
    const isAuthenticated = Boolean(data.email && data.email.length > 0);
    set({ isAuthenticated });
  } catch (e) {
    console.error("ERROR LOGIN STATE API CALL", e);
    set({ isAuthenticated: false, accessToken: null });
  }
};
