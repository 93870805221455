import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AetherService } from "../../services/AetherSerivce";
import { useBrandStoreStore } from "../../stores/Brands/BrandsStore";
import {
  Content,
  HeroText,
  Title,
  StyledTabList,
  StyledTab,
  StyledEditTextarea,
} from "./EmailFlow.styles";

import "react-edit-text/dist/index.css";
import { ToastContainer, toast } from "react-toastify";
import { Tabs, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { Container } from "../productdescription/ProductDescription.styles";

export const EmailFlow = () => {
  const getWelcomeSendTime = (index: number) => {
    switch (index) {
      case 0:
        return "Send after 15 minutes";

      case 1:
        return "Send after 24 hours";

      case 2:
        return "Send after 72 hours";

      default:
        return "";
    }
  };

  const getAbandonedCheckoutSendTime = (index: number) => {
    switch (index) {
      case 0:
        return "Send after 15 minutes";

      case 1:
        return "Send after 2 hours";

      case 2:
        return "Send after 24 hours";

      default:
        return "";
    }
  };

  const getPostPurchaseSendTime = (index: number) => {
    switch (index) {
      case 0:
        return "Send after 1 hour";

      case 1:
        return "Send after 48 hours";

      case 2:
        return "Send after 10 days";

      default:
        return "";
    }
  };

  const { id } = useParams();
  const { getBrandName } = useBrandStoreStore();

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [welcomeEmails, setWelcomeEmails] = useState<string[]>([]);
  const [abandonedCheckoutEmails, setAbandonedCheckoutEmails] = useState<
    string[]
  >([]);
  const [postPurchaseEmails, setPostPurchaseEmails] = useState<string[]>([]);

  const [emailFlowId, setEmailFlowId] = useState<string>("");
  const [isEditing, setIsEditing] = useState(false);

  const editTextStyle = {
    minHeight: "18vh",
    maxHeight: "25vh",
    fontSize: "16px",
    lineHeight: "24px",
    borderRadius: "6px",
  };

  const editEmailArray = (
    newValue: string,
    array: Array<string>,
    index: number,
    setStateVar: Function
  ) => {
    const newEmailArray = array.slice();
    newEmailArray[index] = newValue;
    setStateVar(newEmailArray);
    return newEmailArray;
  };

  const saveWelcome = async (newValue: string, index: number) => {
    try {
      const newWelcome = editEmailArray(
        newValue,
        welcomeEmails,
        index,
        setWelcomeEmails
      );
      await AetherService.put("/email-flow/" + emailFlowId, {
        welcome_emails: newWelcome,
      });
      toast.success("Saved your changes!");
    } catch (e) {
      toast.error(
        "Failed to save your changes. Please copy and paste your text to another document."
      );
      console.error(e);
    }
  };

  const saveAbandonedCheckout = async (newValue: string, index: number) => {
    try {
      const newAbandonded = editEmailArray(
        newValue,
        abandonedCheckoutEmails,
        index,
        setAbandonedCheckoutEmails
      );
      await AetherService.put("/email-flow/" + emailFlowId, {
        abandoned_checkout_emails: newAbandonded,
      });
      toast.success("Saved your changes!");
    } catch (e) {
      toast.error(
        "Failed to save your changes. Please copy and paste your text to another document."
      );
      console.error(e);
    }
  };

  const savePostPurchase = async (newValue: string, index: number) => {
    try {
      const newPostPurchase = editEmailArray(
        newValue,
        postPurchaseEmails,
        index,
        setPostPurchaseEmails
      );
      await AetherService.put("/email-flow/" + emailFlowId, {
        post_purchase_emails: newPostPurchase,
      });
      toast.success("Saved your changes!");
    } catch (e) {
      toast.error(
        "Failed to save your changes. Please copy and paste your text to another document."
      );
      console.error(e);
    }
  };

  useEffect(() => {
    const getEmailFlow = async () => {
      try {
        const { data } = await AetherService.get(`/product/email-flow/${id}`);

        if (data && data.length > 0) {
          const emailFlowData = data[0];

          setWelcomeEmails(emailFlowData.welcome_emails);
          setAbandonedCheckoutEmails(emailFlowData.abandoned_checkout_emails);
          setPostPurchaseEmails(emailFlowData.post_purchase_emails);
          setEmailFlowId(emailFlowData._id);
        }
      } catch (err) {
        console.log(err);
      } finally {
        setIsLoading(false);
      }
    };

    getEmailFlow();
  }, [id]);

  if (!id) return null;

  if (isLoading) return null;

  if (!emailFlowId) return null;
  return (
    <Container>
      <Content>
        <HeroText>{getBrandName(id) || ""}</HeroText>
        <Tabs>
          <Title>High Converting Email Flows</Title>
          <StyledTabList>
            <StyledTab>Welcome Emails</StyledTab>
            <StyledTab>Abandoned Checkout</StyledTab>
            <StyledTab>Post Purchase</StyledTab>
          </StyledTabList>

          <div>
            <>
              <TabPanel>
                {welcomeEmails.map((emailFlow: string, index: number) => (
                  <div>
                    <HeroText>{getWelcomeSendTime(index)}</HeroText>
                    <StyledEditTextarea
                      rows={4}
                      onSave={(e) => {
                        saveWelcome(e.value, index);
                        setIsEditing(false);
                      }}
                      value={emailFlow || "Failed to fetch Email Flows"}
                      onEditMode={() => setIsEditing(true)}
                      isEditing={isEditing}
                      style={editTextStyle}
                      onChange={(e) =>
                        editEmailArray(
                          e.target.value,
                          welcomeEmails,
                          index,
                          setWelcomeEmails
                        )
                      }
                    />
                  </div>
                ))}

                <br />
              </TabPanel>
              <TabPanel>
                {/* <Title>Abandoned Checkout Emails:</Title> */}
                {abandonedCheckoutEmails.map(
                  (emailFlow: string, index: number) => (
                    <div>
                      <HeroText>{getAbandonedCheckoutSendTime(index)}</HeroText>

                      <StyledEditTextarea
                        rows={4}
                        onSave={(e) => {
                          saveAbandonedCheckout(e.value, index);
                          setIsEditing(false);
                        }}
                        value={emailFlow}
                        onEditMode={() => setIsEditing(true)}
                        isEditing={isEditing}
                        style={editTextStyle}
                        onChange={(e) =>
                          editEmailArray(
                            e.target.value,
                            abandonedCheckoutEmails,
                            index,
                            setAbandonedCheckoutEmails
                          )
                        }
                      />
                    </div>
                  )
                )}

                <br />
              </TabPanel>
              <TabPanel>
                {/* <Title>Post Purchase Emails:</Title> */}
                {postPurchaseEmails.map((emailFlow: string, index: number) => (
                  <div>
                    <HeroText>{getPostPurchaseSendTime(index)}</HeroText>

                    <StyledEditTextarea
                      rows={4}
                      onSave={(e) => {
                        savePostPurchase(e.value, index);
                      }}
                      value={emailFlow}
                      onEditMode={() => setIsEditing(true)}
                      isEditing={isEditing}
                      style={editTextStyle}
                      onChange={(e) =>
                        editEmailArray(
                          e.target.value,
                          postPurchaseEmails,
                          index,
                          setPostPurchaseEmails
                        )
                      }
                    />
                  </div>
                ))}
              </TabPanel>
            </>
          </div>
        </Tabs>
      </Content>
      <ToastContainer />
    </Container>
  );
};
