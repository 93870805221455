import { useState } from "react";

import {
  ActionTitle,
  Container,
  ImageContainer,
  LoginButton,
  LoginContainer,
  LoginContent,
  StyledInput,
  Subtitle,
  SubtitleSpan,
  Title,
  StyledLink,
} from "./ChangePassword.styles";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useParams } from "react-router-dom";
import { AetherService } from "../../services/AetherSerivce";

export const ChangePassword = () => {
  const { id } = useParams();
  const [password, setPassword] = useState<string>("");
  console.log(id);

  const onChangePassword = async () => {
    try {
      const response = await AetherService.put(`/auth/change-password/${id}`, {
        id,
        password,
      });
      console.log(response.data);
      toast.success(response.data.message);
      if (response.data.message === "Password changed successfully") {
        setTimeout(() => {
          window.location.href = "/";
        }, 2000);
      }
    } catch (err) {
      const error = err as any;
      toast.error(
        error.response?.data?.message ||
          "Failed to change password, request new link"
      );
      console.error(err);
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      onChangePassword();
    }
  };
  return (
    <Container>
      <ImageContainer>{/* <Initial /> */}</ImageContainer>
      <LoginContainer>
        <LoginContent>
          <ActionTitle>CHANGE PASSWORD</ActionTitle>
          <Title>Create a new password</Title>

          <StyledInput
            value={password}
            placeholder="Enter New Password"
            onChange={(e) => setPassword(e.target.value)}
            label="Password"
            type="password"
            onKeyDown={handleKeyPress}
          />
          <LoginButton onClick={onChangePassword}>Change Password</LoginButton>
          <Subtitle>
            Back to{" "}
            <SubtitleSpan>
              <StyledLink to={"/"}>Login?</StyledLink>
            </SubtitleSpan>
          </Subtitle>
          <ToastContainer />
        </LoginContent>
      </LoginContainer>
    </Container>
  );
};
