import { createTrackedSelector } from "react-tracked";
import { create } from "zustand";
import { checkIsAuth } from "./checkIsAuth";
import { clearAuth } from "./clearAuth";
import { setIsAuth } from "./setIsAuth";

export type AuthStore_T = {
  isAuthenticated: boolean;
  accessToken: string | null;
  checkIsAuth: () => void;
  setIsAuth: (isAuth: boolean, accessToken?: string) => void;
  clearAuth: () => void;
};

const useStore = create<AuthStore_T>((set) => {
  // Try to load authentication state from sessionStorage on initialization
  const storedAuthData = sessionStorage.getItem("authData");
  const initialState = storedAuthData
    ? JSON.parse(storedAuthData)
    : {
        isAuthenticated: false,
        accessToken: null,
      };

  set(initialState);

  return {
    isAuthenticated: initialState.isAuthenticated,
    accessToken: initialState.accessToken,
    checkIsAuth: () => checkIsAuth(set),
    setIsAuth: (isAuth: boolean, accessToken: string | null = null) => {
      setIsAuth(isAuth, accessToken, set);
      // Store authentication state in sessionStorage
      sessionStorage.setItem(
        "authData",
        JSON.stringify({ isAuthenticated: isAuth, accessToken })
      );
    },
    clearAuth: () => {
      clearAuth(set);
      // Clear authentication state from sessionStorage
      sessionStorage.removeItem("authData");
    },
  };
});

export const useAuthStore = createTrackedSelector(useStore);

// Export the store itself for direct access to state and methods
export default useStore;
