import styled from "styled-components";
import { LoaderSpinner } from "../../atoms/Loader/Loader.styles";

export const Container = styled.div`
  width: 100%;
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 1.2rem 1.2rem 1.2rem 0.7rem;
  border-radius: 6px;

  &:hover {
    background: var(--dashboard-background);
  }
`;

type TitleProps = {
  "data-selected"?: boolean;
};

export const Title = styled.p.attrs<TitleProps>((props) => ({
  "data-selected": props["data-selected"],
}))<TitleProps>`
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  color: ${(props) =>
    props["data-selected"] ? "var(--brand-color-1)" : "#160042"};
`;

export const ExpandSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`;

export const ExpandSectionTitle = styled.p`
  font-size: 14px;
  line-height: 24px;
  padding: 0.5rem 1.5rem;
  border-radius: 6px;
  color: var(--third-text);
  user-select: none;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  margin-top: 0.625rem;
  margin-bottom: 7px;

  &[data-selected="true"] {
    background: var(--brand-color-1);
    color: var(--primary-white-text);
  }

  &[data-selected="false"] {
    &:hover {
      background: #e2eaff;
    }
  }
`;

export const ChevronContainer = styled.div`
  margin-right: -2rem;
  transform: rotate(0deg);
  transition: transform 200ms;

  &[data-open="true"] {
    transform: rotate(180deg);
  }
`;

export const DeleteText = styled.span`
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  color: var(--brand-color-1);
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  margin-left: auto;
  margin-right: 0.5rem;
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 1rem;
`;

export const CustomLoaderSpinner = styled(LoaderSpinner)`
  margin-left: auto;
  width: 24px;
  height: 24px;
`;
