type Props = {
    readonly size?: string
    readonly color?: string
}

export const DashboardIcon = ({}: Props) => {
    return (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "flex-end", height: "50px", marginBottom:"20px" }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="512" height="512" enableBackground="new 0 0 512 512" viewBox="0 0 512 512" id="empty-folder">
      <polygon fill="none" stroke="#4A486A" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="10" points="380.946 241.847 101.605 241.847 31.77 381.517 450.781 381.517"></polygon>
      <rect width="419.011" height="113.483" x="31.77" y="381.517" fill="none" stroke="#4A486A" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="10"></rect>
      <path fill="none" stroke="#4A486A" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="10" d="M348.607 438.258c0 10.981-8.896 19.877-19.877 19.877-6.501 0-12.271-3.118-15.901-7.951-2.49-3.321-3.976-7.455-3.976-11.926 0-4.47 1.485-8.604 3.976-11.927 3.631-4.832 9.4-7.95 15.901-7.95C339.712 418.381 348.607 427.277 348.607 438.258zM173.691 438.258c0 4.471-1.483 8.604-3.975 11.926-3.622 4.833-9.399 7.951-15.901 7.951-10.973 0-19.877-8.896-19.877-19.877 0-10.98 8.904-19.877 19.877-19.877 6.502 0 12.279 3.118 15.901 7.95C172.208 429.654 173.691 433.788 173.691 438.258z"></path>
      <path fill="none" stroke="#4A486A" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="10" d="M308.854,438.258c0,4.471,1.485,8.604,3.976,11.926H169.716c2.491-3.321,3.975-7.455,3.975-11.926c0-4.47-1.483-8.604-3.975-11.927h143.113C310.339,429.654,308.854,433.788,308.854,438.258z"></path>
      <path fill="none" d="M420.431,123.382h-85.695V66.619c0-12.341-10.012-22.352-22.352-22.352H178.616c-12.34,0-22.352,10.011-22.352,22.352v56.764H70.569c-8.38,0-15.182,6.801-15.182,15.182V459.27c0,8.381,6.801,15.182,15.182,15.182h349.861c8.381,0,15.182-6.801,15.182-15.182V138.563C435.613,130.183,428.811,123.382,420.431,123.382z M350.595,138.563v25.399c0,8.381-6.801,15.182-15.182,15.182H176.587c-8.38,0-15.182-6.801-15.182-15.182v-25.399H350.595z M420.431,437.088H91.57V198.345h328.861V437.088z"></path>
  </svg>
  </div>
);}