import styled, { css } from "styled-components"
import { Button } from "../../atoms/Button/Button"
import { Input } from "../../atoms/Input/Input"
import { Link } from 'react-router-dom';

type CheckboxProps = {
    hasAttemptedRegister: boolean;
    checked?: boolean; 
};

export const Container = styled.div`
    width: 100%;
    min-height: 100%;
    display: flex;

    @media (max-width: 768px) {
        flex-direction: column;
      }
`

export const ImageContainer = styled.div`
    width: 50%;
    height: 100vh;
    background: #F7F8FE;

    @media (max-width: 768px) {
        width: 100%;
        height: auto;
      }
`

export const RegisterContainer = styled.div`
    width: 50%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 768px) {
        width: 100%;
        height: auto;
      }
`

export const RegisterContent = styled.div`
    width: 70%;
    padding: 1rem;

    @media (max-width: 768px) {
        width: 100%;
      }
`

export const StyledInput = styled(Input)`
    margin-top: 2.5rem;
    width: 100%;
`

export const Title = styled.h1`
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    margin-top: 1rem;
    margin-bottom: 1rem;
    // remove margin-bottom when app review approved
`

export const ActionTitle = styled.p`
    line-height: 24px;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 1.25px;
    color: var(--brand-color-1);
`

export const RegisterButton = styled(Button)`
    width: 100%;
    margin-top: 1.5rem;
`

export const Subtitle = styled.p`
    line-height: 24px;
    font-size: 14px;
    margin-top: 5rem;
`

export const SubtitleSpan = styled.span`
    font-weight: 500;
    color: var(--brand-color-1);
`

export const ContentRow = styled.div`
    display: flex;
    gap: 1rem;
`

export const StyledLink = styled(Link)`
  color: var(--brand-color-1);
  text-decoration: none;
`;



export const CheckboxContainer = styled.div`
    display: flex;
    align-items: center;
    margin-top: 1rem;
`;

export const StyledCheckbox = styled.input.attrs({ type: 'checkbox' })<{ hasAttemptedRegister: boolean }>`
    position: absolute;
    opacity: 0;
    z-index: 1;
    width: 24px;
    height: 24px;
    margin-right: 1rem;
    cursor: pointer;

    &:not(:checked) + div {
        border: ${props => props.hasAttemptedRegister ? 'solid 1px red' : 'solid 1px#EEF4FF'};
    }
`;

export const CheckboxWrapper = styled.div<CheckboxProps>`
    width: 24px;
    height: 24px;
    border: solid 1px var(--dashboard-background);
    background: #EEF4FF;
    
    border-radius: 6px;
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin-right: 1rem;

    // Conditional styling based on the prop
    ${props => props.hasAttemptedRegister && css`
        border: solid 1px red;
    `}

    ${StyledCheckbox}:checked + & {
        background-color: var(--brand-color-1);
    }
`;

export const CheckboxCheckmark = styled.span<{ checked: boolean }>`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: ${props => props.checked ? 'block' : 'none'};

    &:after {
        content: '\\2713'; // Unicode for checkmark symbol
        color: var(--primary-white-text); // White color
        font-weight: bold; // Makes the checkmark thicker
        font-size: 16px; // Adjust the size as needed
    }
`;

// Styled component for the label
export const CheckboxLabel = styled.label`
    cursor: pointer;
    font-size: 14px;
`;