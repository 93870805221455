import styled from "styled-components"

export const SelectContainer = styled.div`
    position: relative;
    width: 100%;
`

export const SelectContentContainer = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.75rem 1rem;
    border-radius: 6px;
    cursor: pointer;
    background: var(--dashboard-background);

    &[data-haserror="true"] {
        border-color: #ef4444;
    }
`

export const SelectValue = styled.p`
    font-size: 14px;
    line-height: 24px;
    color: var(--brand-color-2);
`

export const SelectLabel = styled.p`
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    position: absolute;
    bottom: calc(100% + 0.5rem);
`

export const OptionSection = styled.div`
    width: 100%;
    border: solid 1px var(--input-border-color);
    border-radius: 6px;
    background: var(--dashboard-background);

    display: flex;
    flex-direction: column;
    margin-top: 0.5rem;
    max-height: 200px;
    overflow-y: auto;
`

export const OptionItem = styled.p`
    font-size: 14px;
    line-height: 24px;
    color: var(--brand-color-2);
    cursor: pointer;
    padding:  0.75rem 1rem;
    border-radius: 6px;
    transition: background 250ms;
    
    &:hover {
        background: var(--dashboard-background);
    }
`