import { useEffect, useRef, useState } from "react"
import { Chevron } from "../../icons/Chevron"
import { OptionSection, SelectContainer, SelectContentContainer, SelectLabel, SelectValue } from "./Select.styles"
import { SelectOption } from "./SelectOption"

type Props = {
    readonly value: string
    readonly label?: string
    readonly options: string[]
    onSelectOption: (value: string) => void
    readonly hasError?: boolean
}

export const Select = ({value, label = "", options, onSelectOption, hasError = false}: Props) => {
    const [isOpen, toggleIsOpen] = useState<boolean>(false)
    const selectRef = useRef<HTMLDivElement>(null)

    useEffect(() => {

        const onHandleClick = (e: any) => {
            if (selectRef.current && !selectRef.current.contains(e.target)) {
                toggleIsOpen(false)
            }
        }

        document.addEventListener("mouseup", onHandleClick)

        return () => {
            document.removeEventListener("mouseup", onHandleClick)
        }

    }, [selectRef])

    const onClickOpen = () => {
        toggleIsOpen((prev) => !prev)
    }

    const onClickOption = (value: string) => {
        onSelectOption(value)
        onClickOpen()
    }

    const renderLabel = label && <SelectLabel>{label}</SelectLabel>

    const renderOptions = options.map((option) => (
        <SelectOption key={option} value={option} onClick={onClickOption}/>
    ))

    const optionsSection = isOpen && (
        <OptionSection>
            {renderOptions}
        </OptionSection>
    )

    const selectValueString = value ? value : "Select"

    return (
        <SelectContainer ref={selectRef}>
            {renderLabel}

            <SelectContentContainer onClick={onClickOpen} data-haserror={hasError}>
                <SelectValue>{selectValueString}</SelectValue>
                <Chevron size="16" color="var(--third-text)" />
            </SelectContentContainer>

            {optionsSection}
        </SelectContainer>
    )
}