// CustomToastMessage.tsx

import React from 'react';

type ToastCustomMessageProps = {
  message: string;
};

export const ToastCustomMessage: React.FC<ToastCustomMessageProps> = ({ message }) => {
  return (
    <div style={{ color: 'var(--brand-color-1)', marginLeft: '10px' }}> 
      {message}
    </div>
  );
};
