import { createTrackedSelector } from "react-tracked"
import { create } from "zustand"
import { validateCreateProductInputs } from "./validateCreateProductInputs"

export type CreateProductContentErrorState_T = {
    product: boolean
    feature: boolean
    benefit: boolean
    feel: boolean
    language: boolean
}

export type CreateProductStore_T = {
    productText: string
    featureText: string
    benefitText: string
    // feelofcopyOption: string
    languageOption: string

    productnameText: string

    productNameSuggestions: string[]
    setProductNameSuggestions: (suggestions: string[]) => void

    setProductText: (productText: string) => void
    setFeatureText: (featureText: string) => void
    setBenefitText: (benefitText: string) => void
    // setFeelofcopyOption: (feelofcopyOption: string) => void
    setLanguageOption: (languageOption: string) => void

    // Error handling
    CreateProductContentErrorState: CreateProductContentErrorState_T
    validateCreateProductInputs: () => boolean
}

const useStore = create<CreateProductStore_T>((set, get) => ({
    productText: "",
    featureText: "",
    benefitText: "",
    // feelofcopyOption: "",
    languageOption: "",

    productnameText: "",

    productNameSuggestions: [],
    setProductNameSuggestions: (suggestions: string[]) => set({ productNameSuggestions: suggestions }),

    setProductText: (productText: string) => set({ productText }),
    setFeatureText: (featureText: string) => set({ featureText }),
    setBenefitText: (benefitText: string) => set({ benefitText }),
    // setFeelofcopyOption: (feelofcopyOption: string) => set({ feelofcopyOption }),
    setLanguageOption: (languageOption: string) => set({ languageOption }),


    // Error Handling
    CreateProductContentErrorState: {
        product: false,
        feature: false,
        benefit: false,
        feel: false,
        language: false,
    },
    validateCreateProductInputs: () => validateCreateProductInputs(set, get),
}))

export const useCreateProductStore = createTrackedSelector(useStore)