import styled from "styled-components";

type CampaignDropdownProps = {
  expanded?: boolean;
};

export const CampaignDropdownContainer = styled.div<CampaignDropdownProps>`
  width: 255px; // Fixed width
  height: auto;
  padding: 20px;
  margin-right: 45px;
  background: var(--dashboard-background);
  border-radius: 8px;
  position: relative;
  z-index: 10;
`;

export const ChevronContainer = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-left: 100%;
  transform: rotate(0deg);
  transition: transform 200ms;

  &[data-open="true"] {
    transform: rotate(180deg);
  }
`;

export const DropdownButton = styled.button`
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  border: none;
  outline: none;
  font-size: 14px;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  color: var(--brand-color-1);
`;

// export const CheckboxContainer = styled.div`
//   display: flex;
//   flex-direction: column;
//   gap: 20px;
//   max-height: 180px;
//   overflow-y: auto;
//   background: var(--dashboard-background);
//   position: absolute;
//   top: 120%;
//   left: 0;
//   width: 100%;
//   padding-left: 10px;
//   border-radius: 8px;

// & > * {
//   // Select all direct children
//   direction: ltr; // Revert the direction for the content
// }

//   // Styling for Webkit browsers (e.g., Chrome, Safari)
//   &::-webkit-scrollbar {
//     width: 5px; // Adjust the width to make it thinner
//   }

//   &::-webkit-scrollbar-thumb {
//     background-color: var(--brand-color-1); // Brand color
//     border-radius: 2px; // Make it a bit rounded
//   }
// `;

export const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-height: 180px;
  overflow-y: auto;
  padding: 10px;
  border-radius: 8px;
  background: var(--dashboard-background);
  top: 120%;
  left: 0;
  width: 100%;
  margin-top: 10px;

  & > * {
    // Select all direct children
    direction: ltr; // Revert the direction for the content
  }

  &::-webkit-scrollbar {
    direction: rtl;
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    direction: rtl;
    background-color: var(--brand-color-1);
    border-radius: 2px;
`;

export const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 5px;
  margin-left: 10px;
  color: #8195d5;
  gap: 15px;
  margin-bottom: 10px;

  &:first-child {
    margin-top: 0.625rem;
  }

  font-size: 14px;

  input[type="checkbox"] {
    min-width: 1.1rem;
    min-height: 1.1rem;
    width: 1.1rem;
    height: 1.1rem;
    border-radius: 4px; // Smooth edges
    appearance: none; // Remove default appearance
    background-color: #dfe0e6;
    cursor: pointer;
    &:checked {
      background-color: var(--brand-color-1);
    }
  }
`;

export const DropdownContentContainer = styled.div`
  background: var(
    --dashboard-background
  ); // Use the same background as CheckboxContainer
  border-radius: 8px; // Maintain consistency with CheckboxContainer styling
  position: absolute;
  top: 120%;
  left: 0;
  width: 100%;
  padding: 10px; // Adjust as necessary
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); // Optional, for dropdown shadow effect
`;

export const DropdownWrapper = styled.div`
  border-radius: 8px;
  position: absolute;
  top: 100%; // Adjust if necessary to position right below the button
  left: 0;
  width: 100%;
  z-index: 10;
`;

export const ToggleAllButton = styled.label`
  background: var(--brand-color-1);
  color: var(--primary-white-text);
  padding: 12px 24px;
  border-radius: 6px;
  border: none;
  font-size: 14px;
  line-height: 24px;
  cursor: pointer;
  display: block; // Ensure it behaves like a block-level element
  width: 100%;
  text-align: center; // Center the text if needed
`;

export const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;
