import { useEffect, useState, useRef } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { BrandNav } from "../../molecules/BrandNav/BrandNav";
import { AetherService } from "../../services/AetherSerivce";
import { useBrandStoreStore } from "../../stores/Brands/BrandsStore";
import {
  Container,
  SideMenuContainer,
  Title,
  BrandListContainer,
  StyledModalContent,
  WarningIconContainer,
  ModalTitle,
  ModalText,
  ButtonContainer,
  KeepButton,
  DeleteButton,
  WhiteBackgroundInput,
} from "./DashboardSideNav.styles";
import { toast } from "react-toastify";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { WarningIcon } from "../../icons/Warning";
import { CloseIcon } from "../../icons/CloseIcon";
import ToastCustomIcon from "../../icons/ToastCustomIcon";
import { ToastCustomMessage } from "../../atoms/ToastCustomMessage/ToastCustomMessage";

export const DashboardSideNav = () => {
  const { brands, addBrand, removeBrand } = useBrandStoreStore();

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>("");
  const [allBrands, setAllBrands] = useState<JSX.Element[]>([]); // Initialize with an empty array

  const brandListRef = useRef<HTMLDivElement>(null);

  const handleSearchChange = (searchTerm: string) => {
    setSearchValue(searchTerm);
  };
  const [brandToDelete, setBrandToDelete] = useState<string>("");

  const onDeleteBrand = async () => {
    // THIS SHOULD ONLY RUN ON CLICK WHEN USER DOUBLE CONFIRMS DELETION!!!

    try {
      await AetherService.delete("/product/" + brandToDelete); // TODO Backend: Cascading delete...
      removeBrand(brandToDelete);
      onCloseModal();

      // If user has no brands, go to dash
      const { data } = await AetherService.get("/user/product/all");

      toast.success(
        <ToastCustomMessage message="Successfully deleted your brand" />,
        {
          className: "custom-toast-success",
          icon: <ToastCustomIcon />,
          closeButton: (
            <div style={{ margin: 0 }}>
              <CloseIcon size="12" color="var(--brand-color-1)" />
            </div>
          ),
        }
      );

      if (data.length < 1) {
        navigate("/dashboard/");
        return;
      }

      // otherwise, go to latest after delete
      const latestId = data.reverse()[0]._id;
      const feature = "product-description";
      navigate(`/dashboard/${latestId}/${feature}`);
    } catch (e) {
      toast.error("Failed to delete your brand");
      console.error(e);
    }
  };

  const [open, setOpen] = useState(false);

  const onOpenModal = (id: string) => {
    setBrandToDelete(id);
    setOpen(true);
  };

  const onCloseModal = () => setOpen(false);

  useEffect(() => {
    const filteredBrands = Object.entries(brands).filter(([_, brandData]) =>
      brandData.toLowerCase().includes(searchValue.toLowerCase())
    );

    const reversedBrands = filteredBrands.reverse();
    const brandIds = reversedBrands.map(([id]) => id);
    const htmlBrands = reversedBrands.map(([id, brandData]) => (
      <BrandNav
        key={id}
        id={id}
        name={brandData}
        onDelete={() => onOpenModal(id)}
        latestBrandId={brandIds[0]}
      />
    ));

    setAllBrands(htmlBrands); // Update the state with the value directly
  }, [brands, searchValue]);

  useEffect(() => {
    const fetchAllBrands = async () => {
      try {
        setIsLoading(true);
        // throw new Error("TODO: Fake error to prevent breakage.")
        const { data } = await AetherService.get("/user/product/all");

        console.log("BRANDS FROM DB: ", data);

        data.forEach((brand: { _id: string; name: string }) => {
          addBrand(brand._id, brand.name);
        });
      } catch (err) {
        console.log(err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchAllBrands();
  }, [addBrand]);

  useEffect(() => {
    const checkOverflow = () => {
      if (brandListRef.current) {
        const brandListHeight = brandListRef.current.offsetHeight;
        const sideMenuHeight = brandListRef.current.parentElement?.offsetHeight;

        if (sideMenuHeight && brandListHeight > sideMenuHeight) {
          setOverflowing(true);
        } else {
          setOverflowing(false);
        }
      }
    };

    window.addEventListener("resize", checkOverflow);
    checkOverflow();

    return () => {
      window.removeEventListener("resize", checkOverflow);
    };
  }, []);

  const [isOverflowing, setOverflowing] = useState<boolean>(false);

  if (isLoading) return null;

  return (
    <Container>
      <SideMenuContainer>
        <Title>ALL BRANDS</Title>

        <WhiteBackgroundInput
          type="text"
          value={searchValue}
          onChange={(e) => handleSearchChange(e.target.value)}
          placeholder="    Search..."
        />

        <BrandListContainer ref={brandListRef} isOverflowing={isOverflowing}>
          {allBrands}
        </BrandListContainer>
      </SideMenuContainer>
      <Outlet />

      <div>
        <Modal
          open={open}
          onClose={onCloseModal}
          center
          closeIcon={<CloseIcon />}
        >
          <StyledModalContent>
            <WarningIconContainer>
              <WarningIcon />
            </WarningIconContainer>
            <ModalTitle>Delete Brand</ModalTitle>
            <ModalText>
              You're going to delete the brand. Are you sure?
            </ModalText>
            <ButtonContainer>
              <KeepButton onClick={onCloseModal}>No, Keep It.</KeepButton>
              <DeleteButton onClick={onDeleteBrand}>Yes, Delete!</DeleteButton>
            </ButtonContainer>
          </StyledModalContent>
        </Modal>
      </div>
    </Container>
  );
};
