import styled from "styled-components"

export const StyledButton = styled.button`
    background: var(--brand-color-1);
    color: var(--primary-white-text);
    padding: 12px 24px;
    border-radius: 6px;
    border: none;
    font-weight: 500;
    line-height: 24px;
    cursor: pointer;
`