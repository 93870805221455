// import { Cards } from "./home/components/Cards/Cards"
// import { Features } from "./components/Features/Features"
// import { Header } from "./components/Header/Header"
import { useUserStore } from "../../stores/User/UserStore";
import { Pricing } from "../home/components/Pricing/Pricing";
// import { TestAndScale } from "./components/TestAndScale/TestAndScale"
import { useNavigate } from "react-router-dom";

export const Billing = () => {
  const navigate = useNavigate();
  const { user } = useUserStore();
  console.log("Billing page - user email", user.email);

  if (
    user.email === "alexbrandon2030@gmail.com" ||
    user.email === "liam.bergstroms@icloud.com"
  ) {
    navigate({
      pathname: "/dashboard/create",
    });
  }

  return (
    <>
      {/* <Header />
            <TestAndScale />
            <Features /> */}
      {/* <Cards /> */}
      <Pricing />
    </>
  );
};
