import { StyledButton } from "./Button.styles";

type Props = {
  children: string;
  className?: string;
  onClick?: () => void;
  style?: React.CSSProperties;
};

export const Button = ({ children, className, onClick, style }: Props) => {
  return (
    <StyledButton className={className} onClick={onClick} style={style}>
      {children}
    </StyledButton>
  );
};
