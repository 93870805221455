import styled from 'styled-components';
import { Button as BaseButton } from '../../atoms/Button/Button';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
`;

export const SVGContainer = styled.div`
    width: 150px;
    height: 150px;
`;

export const Message = styled.h1`
    margin: 20px 0;
    font-size: 24px;
    color: red; // Example: using red for error messages
`;

export const Instructions = styled.p`
    font-size: 16px;
`;

export const StyledButton = styled(BaseButton)`
    margin-top: 4rem;
`;
