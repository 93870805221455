import React from "react";
import { TooltipProps } from "recharts";

const CustomTooltip: React.FC<TooltipProps<number, string>> = ({
  active,
  payload,
  label,
}) => {
  if (active && payload && payload.length) {
    return (
      <div
        style={{
          backgroundColor: "#F7F8FE",
          padding: "10px",
          border: 0,
          fontFamily: "Inter, sans-serif",
        }}
      >
        <p
          style={{
            fontFamily: "Inter, sans-serif",
            paddingBottom: "10px",
            color: "#8195d5",
          }}
        >
          {label}
        </p>
        {payload.map((entry, index) => (
          <div key={`item-${index}`} style={{ color: "#8195d5" }}>
            <svg
              width="15"
              height="15"
              viewBox="0 0 32 32"
              style={{ marginRight: "5px", verticalAlign: "middle" }}
            >
              <rect width="15" height="15" fill={entry.color} />
            </svg>
            <span style={{ color: "#8195d5" }}>{entry.name}:</span>{" "}
            {entry.name === "cvr" ? `${entry.value}%` : `${entry.value} SEK`}
          </div>
        ))}
      </div>
    );
  }

  return null;
};

export default CustomTooltip;
