import styled from "styled-components"
import { Button } from "../../atoms/Button/Button"
import { Input } from "../../atoms/Input/Input"
import { Select } from "../../atoms/Select/Select"

export const Container = styled.div`
    width: 100%;
    min-height: 100%;
    padding: 1rem;
    display: flex;
    justify-content: center;
`

export const Content = styled.div`
    max-width: 1000px;
    background: var(--primary-white-text);
    padding: 4rem;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
`

export const LoadingContainer = styled.div`
    height: calc(100vh - 122px - 2rem);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
`

export const HeroTitle = styled.h2`
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: var(--brand-color-1);
`

export const Title = styled.h1`
    font-weight: 700;
    font-size: 56px;
    line-height: 72px;
    text-align: center;
`

export const Subtitle = styled.h3`
    font-size: 18px;
    line-height: 32px;
    color: var(--secondary-text);
    padding-bottom: 3rem;
`

export const StyledInput = styled(Input)`
    width: 100%;
    margin-top: 4rem;

    &:first-of-type {
        margin-top: 0;
    }
`

export const SelectSection = styled.div`
    width: 100%;
    display: flex;
    gap: 1rem;
    margin-top: 4rem;
`

export const StyledSelect = styled(Select)`
    width: 100%;
`

export const StyledButton = styled(Button)`
    margin-top: 2rem;
    width: 100%;
`

export const CenteredContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const Message = styled.div`
  margin-top: 0.625rem;
  text-align: center;
`;