import styled from 'styled-components';

export const SubscriptionPlanSummary = styled.div`
    margin: 0 auto;
    text-align: left;
    background-color: var(--brand-color-1);
    color: #fff;
    width: 700px;
    margin-top: 20px;
    height: 50px;
    padding: 15px 0px 0px 20px;
    border-radius: 10px;
`;
