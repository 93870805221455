import styled from "styled-components"
import { Tab, TabList } from 'react-tabs';

export const Container = styled.div`
    padding: 8rem 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden; 
`

export const ContainerCards = styled.div`
    padding: 2rem 1rem;
    display: flex;
    justify-content: center;
    gap: 1rem;
    flex-wrap: wrap;
`

export const HeroTitle = styled.p`
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: var(--brand-color-1);
    text-align: center;
    margin-bottom: 1rem;
`

export const Title = styled.h1`
    font-weight: 700;
    font-size: 56px;
    line-height: 72px;
    color: var(--brand-color-2);
    text-align: center;
`

export const Subtitle = styled.p`
    font-size: 18px;
    line-height: 32px;
    color: var(--secondary-text);
    padding-top: 1rem;
    max-width: 700px;
    text-align: center;
`

export const CardTitle = styled.h2`
    font-weight: 700;
    font-size: 25px;
    line-height: 40px;
    padding-bottom: 1rem;
`

export const CardPrice = styled.h3`
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    padding-bottom: 1.5rem;
`

export const CardFeatureText = styled.p`
    font-size: 12px;
    line-height: 24px;
    color: var(--secondary-text);
`

export const Card = styled.div`
    position: relative;
    border: solid 1px var(--border-color);
    border-radius: 6px;
    padding: 2rem;
    background: white;
    min-width: 352px; 
    max-width: 352px;
    transition: all 250ms;
    cursor: pointer;

    &[data-active="true"] {
        background: var(--brand-color-1);

        ${CardTitle}, ${CardPrice}, ${CardFeatureText} {
            color: var(--primary-white-text);
        }
    }
`



export const CardDivider = styled.div`
    width: 100%;
    height: 1px;
    background: var(--border-color);
    margin: 1rem 0 2rem 0;
`

export const CardButton = styled.button`
    width: 100%;
    border: solid 1px var(--border-color);
    border-radius: 6px;
    padding: 12px;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    background: white;
    cursor: pointer;
    color: var(--brand-color-1)
`

export const CardFeatureRow = styled.div`
    display: flex;
    gap: 1rem;
    padding-bottom: 1rem;
`

export const StyledTab = styled(Tab)`
  flex-grow: 0;
  text-align: center;
  width: 17rem;
  margin: 0;
  padding: 20px; 
  cursor: pointer;
  border-bottom: 2px solid #8195D5;
  color: #8195D5;
  transition: border-color 0.3s ease, color 0.3s ease;

  &.react-tabs__tab--selected {
    color: var(--brand-color-1);
    border-bottom-color: var(--brand-color-1);
  }

  &:not(.react-tabs__tab--selected):hover {
    background: #e2eaff; 
  }
`;

export const StyledTabList = styled(TabList)`
  list-style-type: none; // Remove bullet points
  padding-left: 0; // Removing any default padding
  display: flex;
  justify-content: center;
  margin-bottom: 2rem; 
  margin-top: 1rem; 
`;

export const UnsubscribeSection = styled.div`
    padding-top: 1rem;
    text-align: center;
`;

// Styled component for the unsubscribe text
export const UnsubscribeText = styled.p`
    font-size: 14px;
    color: #818A9C;
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
`;

export const PromoTag = styled.div`
    position: absolute;
    top: 6rem;
    right: 3rem;
    background-color: rgb(255,0,0); // Red ribbon color
    color: var(--primary-white-text);
    padding: 5px 15px;
    font-size: 14px;
    font-weight: bold;
    transform: translate(50%, -50%) rotate(45deg);
    transform-origin: 100% 0; // Adjusted to right top corner
    z-index: 10;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    text-transform: uppercase; // Styling for emphasis
    // Added clip-path for a ribbon cut effect at the end
    
    white-space: nowrap; // Keep the text in a single line
`;


export const GreyText = styled.p`
    font-size: 14px;
    color: #818A9C;
`;