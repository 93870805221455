import { Checkmark } from "../../../../icons/Checkmark"
import { Card, CardButton, CardDivider, CardFeatureRow, CardFeatureText, CardPrice, CardTitle } from "./Pricing.styles"

type Props = {
    title: string
    price: string
    selected: boolean
    showPurchaseButton: boolean
    hasAlreadyHadTrial: boolean
    onClick: () => void
    promoMessage?: string; 
}

 
export const PriceCard = ({ title, price, selected, showPurchaseButton, hasAlreadyHadTrial, onClick }: Props) => {
 
    const selectedColor = selected ? "var(--primary-white-text)" : "var(--brand-color-1)"

    return (
        <Card onClick={onClick} data-active={selected}>
            <CardTitle>{title}</CardTitle>
            <CardPrice>${price}</CardPrice>
            <CardFeatureRow>
                <Checkmark color={selectedColor} />
                <CardFeatureText>Unlimited brands</CardFeatureText>
            </CardFeatureRow>
            <CardFeatureRow>
                <Checkmark color={selectedColor} />
                <CardFeatureText>Early access to new features</CardFeatureText>
            </CardFeatureRow>
            <CardFeatureRow>
                <Checkmark color={selectedColor} />
                <CardFeatureText>Priority support</CardFeatureText>
            </CardFeatureRow>
            <CardFeatureRow>
                <Checkmark color={selectedColor} />
                <CardFeatureText>Saves $3650 in copywriting-costs, monthly</CardFeatureText>
            </CardFeatureRow>
            <CardDivider />
            {showPurchaseButton && <CardButton>{hasAlreadyHadTrial ? "Resubscribe now" : "Try free for 14 days"}</CardButton>}
        </Card>
    )
}