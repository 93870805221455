import { useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { Chevron } from "../../icons/Chevron";
import {
  ChevronContainer,
  Container,
  ExpandSection,
  ExpandSectionTitle,
  Header,
  Title,
  IconContainer,
  CustomLoaderSpinner,
  DeleteText,
} from "./BrandNav.styles";
import { toast } from "react-toastify";
import { AetherService } from "../../services/AetherSerivce";

type Props = {
  readonly id: string;
  readonly name: string;
  readonly onDelete: Function;
  latestBrandId: string;
};

export const BrandNav = ({ id, name, onDelete, latestBrandId }: Props) => {
  const [toggle, setToggle] = useState<boolean>(false);

  const { id: currentBrandId } = useParams<{ id: string }>();
  //@ts-ignore
  const [adCopys, setAdCopys] = useState<Object>({});
  //@ts-ignore
  const [emailFlow, setEmailFlow] = useState<Object>({});

  const [selectedSection, setSelectedSection] = useState<string>(
    "product-description"
  );
  const [activeSection, setActiveSection] = useState<string>(selectedSection);
  const [selectedBrand, setSelectedBrand] = useState<string>("");
  //@ts-ignore
  const [isLoadingDescription, setIsLoadingDescription] =
    useState<boolean>(false);
  const [isLoadingCopys, setIsLoadingCopys] = useState<boolean>(true);
  // const [isLoadingIdeas, setIsLoadingIdeas] = useState<boolean>(false);
  // const [isLoadingCproducts, setIsLoadingCproducts] = useState<boolean>(false);
  const [isLoadingEflows, setIsLoadingEflows] = useState<boolean>(true);

  const navigate = useNavigate();
  const { id: idParam } = useParams();

  useEffect(() => {
    if (currentBrandId === id) {
      setToggle(true);
    } else {
      setToggle(false);
    }
  }, [currentBrandId, id]);

  const handleToggle = () => {
    if (toggle) {
      // If currently expanded, navigate to the latest brand's product description
      navigate(`/dashboard/${latestBrandId}/product-description`);
    } else {
      // If currently collapsed, navigate to this brand's product description
      navigate(`/dashboard/${id}/product-description`);
    }
  };

  useEffect(() => {
    if (idParam === id) {
      setSelectedBrand(idParam);
      setToggle(true);
    } else {
      setSelectedBrand("");
    }
  }, [idParam]);

  // Load Ad Copys
  useEffect(() => {
    const getAdCopys = async () => {
      try {
        const { data } = await AetherService.get(`/product/ad-copy/${idParam}`);
        // TODO: SET GLOBAL STATE VARIABLE WITH AD COPY DATA
        setAdCopys(data);

        if (data.length > 0) {
          setIsLoadingCopys(false);
        }
      } catch (err) {
        console.log(err);
      }
    };

    // Inital load.
    getAdCopys();

    if (isLoadingCopys) {
      // Continue polling if still loading
      const interval = setInterval(getAdCopys, 2000);
      // Clean up the interval on component unmount or when data is loaded
      return () => clearInterval(interval);
    }
  }, [idParam, isLoadingCopys]);

  // Load Eflows
  useEffect(() => {
    const getEmailFlow = async () => {
      try {
        const { data } = await AetherService.get(
          `/product/email-flow/${idParam}`
        );
        // TODO: SET GLOBAL STATE VARIABLE WITH AD COPY DATA
        setEmailFlow(data);

        if (data.length > 0) {
          setIsLoadingEflows(false);
        }
      } catch (err) {
        console.log(err);
      }
    };

    // Inital load.
    getEmailFlow();

    if (isLoadingEflows) {
      // Continue polling if still loading
      const interval = setInterval(getEmailFlow, 2000);
      // Clean up the interval on component unmount or when data is loaded
      return () => clearInterval(interval);
    }
  }, [idParam, isLoadingEflows]);

  const location = useLocation();

  useEffect(() => {
    // Extract the section name from the URL.
    const pathParts = location.pathname.split("/");
    // Assuming that 'dashboard' is at a fixed index in the path
    const sectionIndex =
      pathParts.findIndex((part) => part === "dashboard") + 2;
    const section = pathParts[sectionIndex];

    // Validate if the section is one of the expected sections
    const validSections = [
      "product-description",
      "analytics",
      "ad-copy",
      "email-flow",
    ];
    if (validSections.includes(section)) {
      // Here you would use React Router's state management or context
      // to store the 'section' instead of using local storage.
      if (selectedBrand === id) {
        setSelectedSection(section);
        setActiveSection(section);
      }
    } else {
      setSelectedSection("");
      setActiveSection("");
    }
  }, [location, selectedBrand, id, setSelectedSection, setActiveSection]);

  const gotoProductFeature = async (
    feature: string,
    isLoading: boolean,
    isLocked: boolean
  ) => {
    if (isLoading) {
      toast.warning("Please wait. AI is still generating this.");
      return;
    }

    if (isLocked) {
      toast.warning("This feature is coming soon! :)");
      return;
    }

    if (selectedBrand !== id) {
      setActiveSection(feature);
      setSelectedSection(feature);

      // LOCALSTORAGE NOT ALLOWED IN SHOPIFY TERMS:
      // localStorage.setItem("selectedSection", feature);
      setSelectedBrand(id);
    } else {
      setActiveSection(feature);
      setSelectedSection(feature);

      // LOCALSTORAGE NOT ALLOWED IN SHOPIFY TERMS:
      // localStorage.setItem("selectedSection", feature);
    }

    navigate(`/dashboard/${id}/${feature}`);
  };

  const expandSection = toggle && (
    <ExpandSection>
      <ExpandSectionTitle
        data-selected={activeSection === "product-description"}
        onClick={() =>
          gotoProductFeature("product-description", isLoadingDescription, false)
        }
      >
        Product Description
        {isLoadingDescription && <CustomLoaderSpinner />}
      </ExpandSectionTitle>

      <ExpandSectionTitle
        data-selected={activeSection === "ad-copy"}
        onClick={() => gotoProductFeature("ad-copy", isLoadingCopys, false)}
      >
        Ad Copy Example
        {isLoadingCopys && <CustomLoaderSpinner />}
      </ExpandSectionTitle>
      {/* 
            <ExpandSectionTitle data-selected={activeSection === "ad-ideas"} onClick={() => gotoProductFeature("ad-idea")}>
                Ad Ideas 
                {isLoadingIdeas && <CustomLoaderSpinner />}
            </ExpandSectionTitle> */}

      {/* <ExpandSectionTitle data-selected={activeSection === "complementary-products"}>
                Complementary Products
                {isLoadingCproducts && <CustomLoaderSpinner />}
            </ExpandSectionTitle> */}

      <ExpandSectionTitle
        data-selected={activeSection === "email-flow"}
        onClick={() => gotoProductFeature("email-flow", isLoadingEflows, false)}
      >
        High Converting Email Flows
        {isLoadingEflows && <CustomLoaderSpinner />}
      </ExpandSectionTitle>

      <ExpandSectionTitle
        data-selected={activeSection === "analytics"}
        onClick={() =>
          gotoProductFeature(
            "analytics",
            isLoadingDescription,
            false /*isLocked*/
          )
        }
      >
        Analytics
        {isLoadingDescription && <CustomLoaderSpinner />}
      </ExpandSectionTitle>

      <DeleteText onClick={() => onDelete()} data-brandID={id}>
        Delete Brand
      </DeleteText>
    </ExpandSection>
  );

  return (
    <Container>
      <Header onClick={handleToggle}>
        <Title data-selected={selectedBrand === id}>{name}</Title>

        <IconContainer>
          <ChevronContainer data-open={toggle}>
            <Chevron size="20" color="var(--brand-color-1)" />
          </ChevronContainer>
        </IconContainer>
      </Header>

      {expandSection}
    </Container>
  );
};
