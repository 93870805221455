import React, { useState } from "react";
import styled from "styled-components";
import { Button as BaseButton } from "../../../../atoms/Button/Button";
import { AetherService } from "../../../../services/AetherSerivce";
import { ToastContainer, toast } from "react-toastify";
import { CustomLoaderSpinner } from "../../../../molecules/BrandNav/BrandNav.styles";
type UnsubscribeModalProps = {
  onClose: () => void;
};

type ModalContentProps = {
  isCentered: boolean;
};

const UnsubscribeModal: React.FC<UnsubscribeModalProps> = ({ onClose }) => {
  const [isUnsubscribed, setIsUnsubscribed] = useState(false);
  const [loading, setIsLoading] = useState(false);
  const [subscribeText, setSubscribeText] = useState(" ");

  const confirmUnsubscribe = async () => {
    setIsLoading(true);
    try {
      await AetherService.put("/adyen-payments/unsubscribe");
      setIsLoading(false);
      setSubscribeText(
        "You are now unsubscribed and will not be billed next month/year. You will keep your access to Brandroid until the term has ended."
      );
      setIsUnsubscribed(true);
      toast.success("Unsubscribed from Brandroid!");
    } catch (e) {
      setIsLoading(false);
      setSubscribeText("Failed to unsubscribe. Please contact support.");
      setIsUnsubscribed(true);

      toast.error("Something went wrong...");
      console.error(e);
    }
  };

  const closeModal = () => {
    setIsUnsubscribed(false);
    onClose();
  };

  return (
    <div>
      <ModalOverlay>
        <ModalContent isCentered={isUnsubscribed}>
          {!isUnsubscribed ? (
            <>
              <p>Are you sure you want to unsubscribe?</p>
              {loading && (
                <CustomLoaderSpinner style={{ margin: "10px auto 0 auto" }} />
              )}
              {!loading && (
                <ButtonWrapper>
                  <ConfirmButton onClick={confirmUnsubscribe}>
                    Yes, Unsubscribe
                  </ConfirmButton>
                  <CancelButton onClick={closeModal}>Cancel</CancelButton>
                </ButtonWrapper>
              )}
            </>
          ) : (
            <>
              <p style={{ fontWeight: "900" }}>{subscribeText}</p>
              <OkButton onClick={closeModal}>OK</OkButton>
            </>
          )}
        </ModalContent>
      </ModalOverlay>
      <ToastContainer />
    </div>
  );
};
// Styled components
const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContent = styled.div<ModalContentProps>`
  background: white;
  padding: 20px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: ${({ isCentered }) =>
    isCentered ? "center" : "flex-start"};
  text-align: center;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  padding-top: 20px;
`;

// Styling the Confirm Button
const ConfirmButton = styled(BaseButton)`
  margin-right: 20px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
`;

// Styling the Cancel Button
const CancelButton = styled(BaseButton)`
  background-color: #160042; // Specific color for the cancel button
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
`;

const OkButton = styled(BaseButton)`
  margin-top: 1rem;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
`;

export default UnsubscribeModal;
