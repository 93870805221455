import { useState, useEffect } from "react";
import { Tabs, TabPanel } from "react-tabs";
import { PriceCard } from "./PriceCard";
import {
  Container,
  ContainerCards,
  HeroTitle,
  Title,
  StyledTab,
  StyledTabList,
  UnsubscribeSection,
  UnsubscribeText,
  GreyText,
} from "./Pricing.styles";
import UnsubscribeModal from "./UnsubscribeModal";
import { useLocation, useNavigate } from "react-router-dom";
import { AetherService } from "../../../../services/AetherSerivce";
import { toast, ToastContainer } from "react-toastify";
import { useUserStore } from "../../../../stores/User/UserStore";

export const Pricing = () => {
  const [selectedTab, setSelectedTab] = useState<string>("Yearly");
  const [showUnsubscribeModal, setShowUnsubscribeModal] = useState(false);
  const [hasActiveSubscription, setHasActiveSubscription] =
    useState<boolean>(false);
  const [hasAlreadyHadTrial, setHasAlreadyHadTrial] = useState<boolean>();
  const [hasUnsubscribed, setHasUnsubscribed] = useState<boolean>();
  const [currentBillingEnd, setCurrentBillingEnd] = useState<string>();
  const [subscriptionType, setSubscriptionType] = useState("");
  const [chargeAmount, setChargeAmount] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const { user } = useUserStore();

  function formatChargeAmount(str: string, subscriptionType: string) {
    let formattedStr;
    if (str.length < 2) {
      // If the string is too short, return it as is or handle the case as needed
      formattedStr = str;
    } else {
      formattedStr = str.slice(0, -2) + "." + str.slice(-2);
    }

    if (subscriptionType.toLowerCase().includes("yearly")) {
      formattedStr += "/year";
    } else {
      formattedStr += "/month";
    }

    // Insert a dot before the last two characters
    return formattedStr;
  }

  const checkSubscriptionStatus = async () => {
    if (
      user.email === "alexbrandon2030@gmail.com" ||
      user.email === "liam.bergstroms@icloud.com"
    )
      return;
    setIsLoading(true);
    try {
      const response = await AetherService.get(
        "/adyen-payments/active-subscription"
      );
      const data = response.data;
      console.log({ isActive: data.isActive });
      setHasActiveSubscription(data.isActive);
      setHasUnsubscribed(data.isUnsubscribed);
      setHasAlreadyHadTrial(data.hasHadFreeTrial);
      console.log(response.data);

      if (data.subscriptionType) {
        setSubscriptionType(
          data.subscriptionType.toLowerCase().includes("yearly")
            ? "Yearly Plan - Full Access"
            : "Monthly Plan - Full Access"
        );

        const formattedChargeAmount = formatChargeAmount(
          data.chargeAmount.toString(),
          data.subscriptionType
        );
        setChargeAmount(formattedChargeAmount);

        const formattedBillingEnd = new Date(data.currentBillingEnd)
          .toISOString()
          .split("T")[0];
        setCurrentBillingEnd(formattedBillingEnd);
      }

      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching subscription status:", error);
    }
  };

  useEffect(() => {
    checkSubscriptionStatus();
  }, []);

  const getPrice = () => {
    return selectedTab === "Yearly" ? "349.99" : "39.99";
  };

  const handleUnsubscribeClick = () => {
    if (hasActiveSubscription && !hasUnsubscribed) {
      setShowUnsubscribeModal(true);
    }
  };

  const navigate = useNavigate();

  if (isLoading) return <div></div>; // TODO Replace with spinner loading

  return (
    <>
      <Container>
        {!hasActiveSubscription ? (
          <div>
            <HeroTitle>PRICING DETAILS</HeroTitle>
            <Title>
              {hasAlreadyHadTrial
                ? "Resubscribe to Brandroid!"
                : "Choose Your Package!"}
            </Title>
            <Tabs
              selectedTabClassName="react-tabs__tab--selected"
              onSelect={(index) =>
                setSelectedTab(index === 0 ? "Yearly" : "Monthly")
              }
            >
              <StyledTabList>
                <StyledTab>Yearly</StyledTab>
                <StyledTab>Monthly</StyledTab>
              </StyledTabList>

              <TabPanel>
                <ContainerCards>
                  <PriceCard
                    title="Yearly - Full Access"
                    price={getPrice()}
                    selected={true}
                    showPurchaseButton={true}
                    hasAlreadyHadTrial={hasAlreadyHadTrial || false}
                    onClick={() => {
                      // Conditional navigation
                      if (location.search) {
                        navigate(
                          `/dashboard/payment${location.search}&subscriptionType=yearly`
                        );
                      } else {
                        navigate("/dashboard/payment?subscriptionType=yearly");
                      }
                    }}
                  />
                </ContainerCards>
              </TabPanel>

              <TabPanel>
                <ContainerCards>
                  <PriceCard
                    title="Monthly - Full Access"
                    price={getPrice()}
                    selected={true}
                    showPurchaseButton={true}
                    hasAlreadyHadTrial={hasAlreadyHadTrial || false}
                    onClick={() => {
                      navigate({
                        pathname: "/dashboard/payment",
                        search: location.search,
                      });
                    }}
                  />
                </ContainerCards>
              </TabPanel>
            </Tabs>
          </div>
        ) : (
          <div>
            <HeroTitle>Billing:</HeroTitle>
            <Title>Your Package</Title>
            <Tabs
              selectedTabClassName="react-tabs__tab--selected"
              onSelect={(index) =>
                setSelectedTab(index === 0 ? "Yearly" : "Monthly")
              }
            >
              <TabPanel>
                <ContainerCards>
                  <PriceCard
                    title={subscriptionType}
                    price={chargeAmount}
                    selected={true}
                    showPurchaseButton={hasUnsubscribed || false}
                    hasAlreadyHadTrial={hasAlreadyHadTrial || false}
                    onClick={async () => {
                      setIsLoading(true);
                      try {
                        AetherService.put("/adyen-payments/subscription", {
                          isUnsubscribed: false,
                        });
                        window.location.reload();
                      } catch (e) {
                        toast.error(
                          "Failed to resubscribe. Please contact support."
                        );
                      }
                      setIsLoading(false);
                    }}
                  />
                </ContainerCards>
              </TabPanel>
            </Tabs>
            <UnsubscribeSection>
              {hasActiveSubscription && !hasUnsubscribed ? (
                <UnsubscribeText onClick={handleUnsubscribeClick}>
                  Unsubscribe
                </UnsubscribeText>
              ) : (
                <GreyText onClick={handleUnsubscribeClick}>
                  You have unsubscribed to Brandroid
                </GreyText>
              )}
              <br />
              <GreyText>
                {hasActiveSubscription && !hasUnsubscribed
                  ? `You will be billed next time at ${currentBillingEnd}`
                  : `You will lose access after ${currentBillingEnd}`}
              </GreyText>
            </UnsubscribeSection>
          </div>
        )}

        {/* Modal for unsubscribe confirmation */}
        {showUnsubscribeModal && (
          <UnsubscribeModal
            onClose={() => {
              setShowUnsubscribeModal(false);
              checkSubscriptionStatus();
            }}
          />
        )}
        <ToastContainer />
      </Container>
    </>
  );
};

export default Pricing;
