import styled from "styled-components";
import { StyledInput } from "../../atoms/Input/Input.styles";

export const Container = styled.div`
  width: 100%;
  display: flex;
`;

export const SideMenuContainer = styled.div`
  width: 100%;
  padding: 2rem 2rem 2rem 2rem; /* Uniform padding for top, right, bottom, and left */
  max-width: 350px; /* Default max-width */
  height: calc(100vh - 122px);
  box-sizing: border-box;
  background: var(--dashboard-background);
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1rem;

  /* Media query for even larger screens */
  @media (min-width: 1568px) {
    padding: 6rem 5rem 2rem 5rem; /* Increased top padding for larger screens */

    max-width: 450px;
  }
`;

export const Title = styled.p`
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  color: var(--brand-color-1);
  align-self: flex-start;
  margin: 1rem 0;
  padding: 0rem 1rem;
`;

interface BrandListContainerProps {
  isOverflowing: boolean;
}

export const BrandListContainer = styled.div<BrandListContainerProps>`
  width: 100%;
  overflow-y: ${(props) => (props.isOverflowing ? "scroll" : "auto")};

  // Adjusting space for the scrollbar
  margin-right: -15px; // This pulls the entire container to the left
  padding-right: 15px; // This pushes the content to the left but leaves the scrollbar where it is
  max-height: 100%; // Adjust as needed

  // Styling for Webkit browsers (e.g., Chrome, Safari)
  &::-webkit-scrollbar {
    width: 6px; // Making the scrollbar thinner
    background-color: #f5f5f5; // This is the scrollbar's background
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(
      --brand-color-1
    ); // This is the color of the draggable scrollbar
    border-radius: 3px;
  }
`;

export const StyledModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3rem;
  gap: 1rem;
`;

export const WarningIconContainer = styled.div`
  color: red;
`;

export const ModalTitle = styled.h2`
  font-weight: 700;
  font-size: 43px;
`;

export const ModalText = styled.p`
  color #8195D5;
  margin-bottom: 1.5rem;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 8px;
  width: 100%;
`;

export const Button = styled.button`
  padding: 10px 20px;
  background-color: ${(props) => props.color};
  color: white;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  margin: 0 5px;
`;

export const DeleteButton = styled(Button)`
  flex-grow: 1;
  background-color: red;
  color: white;
  padding: 40px, 20px, 40px, 20px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  border-radius: 6px;
  width: 250px;
  height: 48px;
`;

export const KeepButton = styled(Button)`
  flex-grow: 1;
  background: #1457ff;
  color: #fff;
  padding: 40px, 20px, 40px, 20px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  border-radius: 6px;
  width: 250px;
  height: 48px;
`;

export const WhiteBackgroundInput = styled(StyledInput)`
  background: white;
  border: none;
  margin-left: 7px;
  width: 91%;

  &::placeholder {
    color: #8195d5;
  }
`;
