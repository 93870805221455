import { useState } from "react";

import {
  ActionTitle,
  Container,
  ImageContainer,
  LoginButton,
  LoginContainer,
  LoginContent,
  StyledInput,
  Subtitle,
  SubtitleSpan,
  Title,
  StyledLink,
} from "./ForgotPassword.styles";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AetherService } from "../../services/AetherSerivce";
import { z } from "zod";

const resetPasswordSchema = z.object({
  email: z.string().email("Invalid email address"),
});

export const ForgotPassword = () => {
  const [email, setEmail] = useState<string>("");

  const onReset = async () => {
    const result = resetPasswordSchema.safeParse({ email });

    if (!result.success) {
      toast.error(result.error.errors[0].message);
      return;
    }
    try {
      const response = await AetherService.post("/auth/forgot-password", {
        email,
      });
      console.log(response.data);
      toast.success(response.data.message);
    } catch (err) {
      toast.error("Send email error, try again");
      console.error(err);
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      onReset();
    }
  };
  return (
    <Container>
      <ImageContainer>{/* <Initial /> */}</ImageContainer>
      <LoginContainer>
        <LoginContent>
          <ActionTitle>FORGOT PASSWORD</ActionTitle>
          <Title>Reset password</Title>

          <StyledInput
            value={email}
            placeholder="Enter Email"
            onChange={(e) => setEmail(e.target.value)}
            label="Email address"
            onKeyDown={handleKeyPress}
          />
          <LoginButton onClick={onReset}>Reset Password</LoginButton>
          <Subtitle>
            Back to{" "}
            <SubtitleSpan>
              <StyledLink to={"/"}>Login?</StyledLink>
            </SubtitleSpan>
          </Subtitle>
          <ToastContainer />
        </LoginContent>
      </LoginContainer>
    </Container>
  );
};
