import React from "react";
import { SuggestionText } from "./SuggestionText.styles";

interface GraphDataItem {
  date: string;
  adcost: number;
  cpc: number;
  revenue: number;
  profit: number;
  cvr: number;
}

interface SuggestionTextContainerProps {
  graphData: GraphDataItem[];
}

const SuggestionTextContainer: React.FC<SuggestionTextContainerProps> = ({
  graphData,
}) => {
  // Calculate averages and other metrics
  const averages = graphData.reduce(
    (acc, item) => {
      console.log("Processing item:", item);
      acc.totalAdCost += item.adcost;
      acc.totalCPC += item.cpc;
      acc.totalRevenue += item.revenue;
      acc.totalProfit += item.profit;
      acc.totalCVR += item.cvr;
      return acc;
    },
    {
      totalAdCost: 0,
      totalCPC: 0,
      totalRevenue: 0,
      totalProfit: 0,
      totalCVR: 0,
      count: graphData.length,
    }
  );

  console.log("Accumulated totals before averaging:", averages);

  const avgCPC = averages.totalCPC / averages.count;
  const avgCVR = (averages.totalCVR / averages.count) * 100; // Convert to percentage
  const avgAOV = averages.totalRevenue / averages.count;

  console.log("Average CPC:", avgCPC);
  console.log("Average CVR (%):", avgCVR);
  console.log("Average AOV:", avgAOV);

  // Determine suggestion based on calculated metrics
  let suggestionText = "No suggestion available.";

  if (avgCPC > 15 || (avgCVR > 5 && avgAOV > 300 && avgCPC > 8)) {
    return (
      <SuggestionText>
        När var senaste gången du testade nytt content? Dina annonser verkar
        inte riktigt träffa helt rätt hos din målgrupp.
        <br />
        <br />
        Här kommer några åtgärder som kan lösa detta problem:
        <br />
        <br />
        <ol>
          <li>
            Testa byta format på din annons. Kör du just nu med en video, testa
            med en bild.
          </li>
          <li>
            Ordna en UGC video som på ett mer genuint sätt kopplar till din
            målgrupp.
          </li>
          <li>
            Byt ut de första 5 sekunderna av din video. En ny hook ger dig inte
            bara en högre chans till att få uppmärksamhet, utan ses i Metas ögon
            som en helt ny annons som kan förbättra din CPM.
          </li>
        </ol>
      </SuggestionText>
    );
  } else if (avgCVR < 1 || (avgCPC < 8 && avgAOV > 300 && avgCVR < 3)) {
    return (
      <SuggestionText>
        Din landningssida är vad som håller dig tillbaka. Produkten är för dyr
        eller för billig beroende på det uppfattade värdet.
        <br />
        <br />
        Här kommer några saker du kan testa för att försöka förbättra detta:
        <br />
        <ol>
          <li>
            Justera priset eller justera det uppfattade värdet genom att vinkla
            om produktbeskrivningen. Testa åt båda hållen, testa sänka priset
            men testa även att höja priset. Ett dyrare pris kan ge ett högre
            uppfattat värde som brukar slå bra vid produkter som "borde vara
            dyrare".
          </li>
          <li>
            Anpassa om produktbeskrivningen till den målgruppen som klickar in
            sig från dina annonser. Ensamstående Annika, 27 år som bor i Solna
            med en son och älskar twilight, ska verkligen känna att produkten är
            till för HENNE. Inte "alla föräldrar".
          </li>
          <li>
            Vill du rikta din butik till en bredare målgrupp, bygg upp separata
            landningssidor och anpassa beskrivningen till den unika användaren
            beroende på från vilken annons de kommer från.
          </li>
        </ol>
      </SuggestionText>
    );
  } else if (avgAOV < 150 || (avgCPC < 8 && avgCVR > 5 && avgAOV < 300)) {
    return (
      <SuggestionText>
        Fokusera på att få upp ditt average order value. Du får in en okej mängd
        kunder och du konverterar många av dem. När de väl köper så köper de
        inte mycket. Det enklaste sättet för dig att förbättra din butik är inte
        genom att få in fler kunder, utan det är genom att låta de kunder som
        redan köper att köpa mer.
        <br />
        <br />
        Du kan göra det genom dessa åtgärder:
        <br />
        <ol>
          <li>
            Höj priset på din produkt. Du kan alltid sälja din produkt för ett
            dyrare pris. Så länge det görs på rätt sätt kommer det inte ge dina
            kunder en ”bättre deal” hos någon annan, utan det kommer positionera
            dig mer exklusivt, mer trovärdigt och med högre kvalité.
          </li>
          <li>
            Ge dina kunder en bra deal vid köp av flera produkter. Vem är mest
            trolig att göra ett köp i din butik? Någon random besökare… eller en
            faktisk kund? Gör det enkelt för dina kunder att köpa mer produkter,
            både på landningssidan men också på tack-sidan.
          </li>
          <li>
            Erbjud dina kunder en kompletterande produkt. När har vi som mest
            uppmärksamhet hos någon? Precis när de gjort ett köp. Det är då de
            läser igenom hela sidan för att säkerställa att allt gick igenom och
            om det finns någon spårningslänk. Här är det bästa stället att
            erbjuda en riktigt bra deal.
          </li>
          <li>
            Erbjud dem en produkt som kommer få upplevelsen de är ute efter
            bättre, drömscenariot de längtar efter hända snabbare eller bara en
            till likadan produkt.
          </li>
        </ol>
      </SuggestionText>
    );
  } else if (avgCPC > 15 && avgCVR < 1 && avgAOV < 1000) {
    return (
      <SuggestionText>
        Överväg att testa en ny produkt. Dina annonser väcker inte tillräckligt
        mycket nyfikenhet och din butik inte tillräckligt mycket känslor. Detta
        går absolut att åtgärda med nya annonser, bättre erbjudanden och en
        noggrannare målgruppsundersökning men ett snabbare alternativ kan vara
        att byta produkt helt. Jag hade rekommenderat en ny annons, ett nytt
        erbjudande och en butik inriktad till en ny målgrupp.
      </SuggestionText>
    );
  } else if (avgCPC < 8 && avgAOV > 300 && avgCVR > 5) {
    return (
      <SuggestionText>
        Bra jobbat! Din data ser riktigt bra ut!
        <br />
        <br />
        Glöm inte att alltid testa nytt annonseringsmaterial och testa nya
        målgrupper för att förbättra din data ytterligare. Här skulle min
        rekommendation vara att börja spendera lite mer på annonsering för att
        skala din butik. Glöm inte att kolla in med mig dagligen så säger jag
        till när jag börjar se din data dra iväg åt fel håll så du kan täppa
        igen den läckan så snabbt som möjligt. Lycka till!
      </SuggestionText>
    );
  }

  return (
    <div>
      <p>{suggestionText}</p>
    </div>
  );
};

export default SuggestionTextContainer;
