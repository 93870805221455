import styled, { keyframes } from "styled-components"

const SpinningAnim = keyframes`
    0% {transform: rotate(0deg)}
    100% {transform: rotate(360deg)}
`

export const LoaderSpinner = styled.div`
    width: 35px;
    height: 35px;
    border: solid 5px var(--brand-color-1);
    border-right: solid 5px transparent;
    border-radius: 50%;

    animation: ${SpinningAnim} 1s infinite;
`