import styled from "styled-components";
import { Tab, TabList } from "react-tabs";
import { EditTextarea } from "react-edit-text";

export const Content = styled.div`
  width: 100%;
  max-width: 900px;
  --primary-text: var(--third-text);
`;

export const HeroText = styled.h1`
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  text-transform: uppercase;
  color: var(--brand-color-1);
`;

export const Title = styled.h2`
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;

  margin-bottom: 1rem;
  color: var(--brand-color-2);
`;

export const StyledTab = styled(Tab)`
  flex-grow: 1;
  text-align: center;
  margin: 0;
  padding: 10px 25px;
  cursor: pointer;
  border-bottom: 2px solid #8195D5; // Grey line on the bottom
  color: #8195D5; // Initial text color same as the bar
  transition: border-color 0.3s ease, color 0.3s ease;

  &.react-tabs__tab--selected {
    color: var(--brand-color-1);
    border-bottom-color: var(--brand-color-1);
    border-top-color: transparent;
    border-left-color: transparent;
    border-right-color: transparent;
  }

  &:not(.react-tabs__tab--selected):hover {
    background: #e2eaff; 
  }
}
`;

export const StyledTabList = styled(TabList)`
  list-style-type: none;
  padding-left: 0;
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
`;

export const BlurredText = styled.div`
  filter: blur(4px);
  user-select: none;
`;

// export const StyledEditTextarea = styled(EditTextarea)`
//   min-height: 10vh;
//   font-size: 16px;
//   line-height: 24px;
//   border-radius: 6px;

//   &:hover {
//     background-color: #f7f8fe;
//   }
// `;

export const StyledEditTextarea = styled(EditTextarea)<{ isEditing?: boolean }>`
  min-height: 65vh;
  max-height: 65vh; // Set a maximum height
  font-size: 16px;
  line-height: 24px;
  border-radius: 6px;
  padding-right: 1rem;

  &:hover {
    background-color: #f7f8fe;
  }

  &::-webkit-scrollbar {
    width: 6px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--brand-color-1);
    border-radius: 3px;
  }
`;
