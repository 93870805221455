import styled from "styled-components"
import { Button } from "../../../atoms/Button/Button"
import { Input } from "../../../atoms/Input/Input"

export const NameOptionContainer = styled.div`
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 1rem;
    padding: 1rem;
    border: solid 1px var(--border-color);
    border-radius: 0.5rem;
    width: 100%;
`

export const NameOptionTitle = styled.p`
    font-size: 14px;
    line-height: 24px;
    color: var(--brand-color-2);
    align-self: flex-start;
    padding: 0.5rem 0;
`

export const StyledInput = styled(Input)`
    width: 100%;
    margin-top: 2.5rem;
    padding: 0.3rem;
`

export const StyledButton = styled(Button)`
    width: 165px;
    margin-top: 3rem;
    font-family: 'Montserrat', sans-serif; 
    font-weight: bold;
`