import { ReactNode, useEffect, useState } from "react";
import { Navigate, useNavigate, useLocation } from "react-router-dom";
import { useAuthStore } from "../stores/Authentication/AuthStore";
import { AetherService } from "../services/AetherSerivce";
import { useUserStore } from "../stores/User/UserStore";

type Props = {
  readonly children: ReactNode;
};

export const ProtectedRoute = ({ children }: Props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const { checkIsAuth, isAuthenticated } = useAuthStore();
  const [isLoadingAuth, setIsLoadingAuth] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const isLocalhost = window.location.hostname === "localhost";

  const { user } = useUserStore();
  console.log("Billing page - user email", user.email);

  const checkSubscriptionStatus = async () => {
    if (
      user.email === "alexbrandon2030@gmail.com" ||
      user.email === "liam.bergstroms@icloud.com"
    )
      return;
    if (isLocalhost) return; // Bypass subscription check on localhost

    setIsLoading(true);
    try {
      const response = await AetherService.get(
        "/adyen-payments/active-subscription"
      );
      const data = response.data;
      console.log({ location });

      const isOnAllowedPaths =
        location.pathname.includes("payment") ||
        location.pathname.includes("billing");
      if (!data.isActive && !isOnAllowedPaths) {
        navigate({
          pathname: "/dashboard/billing",
        });
      }
    } catch (error) {
      console.error("Error fetching subscription status:", error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (
      isLocalhost ||
      user.email === "alexbrandon2030@gmail.com" ||
      user.email === "liam.bergstroms@icloud.com"
    ) {
      return;
    }

    checkSubscriptionStatus();
  }, [location, navigate, user.email]);

  useEffect(() => {
    if (
      isLocalhost ||
      user.email === "alexbrandon2030@gmail.com" ||
      user.email === "liam.bergstroms@icloud.com"
    ) {
      setIsLoadingAuth(false); // Bypass auth check on localhost
      return;
    }

    const verifyAuth = async () => {
      await checkIsAuth();
      setIsLoadingAuth(false);
    };
    verifyAuth();
  }, [checkIsAuth, isLocalhost, user.email]);

  if (isLoadingAuth && isLoading) return <div>Loading...</div>; // Show a loading state

  if (!isLocalhost && !isAuthenticated) return <Navigate to="/" replace />; // Redirect if not authenticated and not on localhost

  return <>{children}</>; // Render children if authenticated or on localhost
};
