//added --legacy-peer-deps

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AetherService } from "../../services/AetherSerivce";
import { useBrandStoreStore } from "../../stores/Brands/BrandsStore";

import { ShopifyLogoImage } from "../../icons/ShopifyLogo";

import { Loader } from "../../atoms/Loader/Loader";

import { useUserStore } from "../../stores/User/UserStore";
import "react-toastify/dist/ReactToastify.css";

import { Container } from "../productdescription/ProductDescription.styles";

import {
  Content,
  HeroText,
  Title,
  AnalyticsText,
  ShopifyStyledButton,
  PurpleShopifyButton,
  ShopifyConnectColumn,
  ConnectText,
  ButtonsContainer,
  ButtonsContainerPopout,
  DetailsContainer,
  FacebookStyledButton,
  StoreNameInput,
  ModalContainer,
  CloseButton,
  Overlay,
  LoadingContainer,
  LoadingTextWarning,
  LeftColumn,
  RightColumn,
  HorizontalContainer,
  ApiKeyInput,
  ErrorText,
  Highlight,
  InstructionText,
  VideoContainer,
  ResponsiveIframe,
} from "./Analytics.styles";

// Import all components
import CampaignSelection from "./CampaignSelection";
import Graph from "./Graph";
import SuggestionTextContainer from "./SuggestionText";

import "react-edit-text/dist/index.css";

import FacebookLogin from "@greatsumini/react-facebook-login";

import { AxiosResponse } from "axios";

const environment = process.env.REACT_APP_ENVIRONMENT;

// Now, you can use the 'environment' variable in your code
let backendBaseUrl = "";
if (environment === "prod") {
  backendBaseUrl = "https://backend.brandroid.ai";
} else {
  backendBaseUrl = "http://localhost:80";
}

export const Analytics = () => {
  const { user } = useUserStore();
  const { id } = useParams();

  const { getBrandName } = useBrandStoreStore();

  const [ActiveSelectedCampaigns, setSelectedCampaigns] = useState<string[]>(
    []
  );

  const [storeName, setStoreName] = useState<string>("");
  const [apiKey, setApiKey] = useState<string>("");

  const [connectedToFacebook, setConnectedToFacebook] = useState(false);

  const [shopifyConnected, setShopifyConnected] = useState(false);

  const [showShopifyModal, setShowShopifyModal] = useState(false);

  const [graphData, setGraphData] = useState<GraphDataItem[]>([]);

  const [allCampaigns, setAllCampaigns] = useState([""]);

  const [facebookData, setFacebookData] = useState([{ data: [] }]);
  const [shopifyData, setShopifyData] = useState([
    { date: "", revenue: 0, cost: 0, numberOfOrders: 0 },
  ]);

  const [isLoading, setIsLoading] = useState(true);

  const [storeNameError, setStoreNameError] = useState("");
  const [apiKeyError, setApiKeyError] = useState("");

  interface ShopifyEntry {
    date: string;
    revenue: number;
    cost: number;
    numberOfOrders: number;
  }
  interface GraphDataItem {
    date: string;
    adcost: number;
    cpc: number;
    revenue: number;
    profit: number;
    cvr: number;
  }

  interface FacebookCampaignEntry {
    campaign_name: string;
    cost_per_inline_link_click: number;
    spend: number;
    date_start: string;
    date_end: string;
    account_currency: string;
  }

  interface FacebookCampaign {
    data: FacebookCampaignEntry[];
  }

  interface MergedData {
    revenue: number;
    spend: number;
    cost_per_inline_link_click: number;
    numberOfOrders: number;
  }

  interface Result {
    date: string;
    adcost: number;
    cpc: number;
    revenue: number;
    profit: number;
    cvr: number;
  }

  async function calculateMetrics(
    shopifyData: ShopifyEntry[],
    facebookData: FacebookCampaign[],
    selectedCampaigns: string[]
  ): Promise<Result[]> {
    const fbDaily: Record<string, MergedData> = {};

    if (
      !facebookData ||
      !shopifyData ||
      facebookData.length < 2 ||
      shopifyData.length < 2
    ) {
      return [];
    }
    const needsConversionItem = facebookData[1].data.find(
      (item) => item.account_currency !== undefined
    );

    const needsConversion = needsConversionItem
      ? needsConversionItem.account_currency
      : null;

    facebookData.forEach((campaign) => {
      campaign.data.forEach((entry) => {
        console.log({ selectedCampaigns });
        if (selectedCampaigns.includes(entry.campaign_name)) {
          const date = entry.date_start;

          fbDaily[date] = fbDaily[date] || {
            spend: 0,
            cost_per_inline_link_click: 0,
            revenue: 0,
          };
          console.log({
            TotalSpend: fbDaily[date].cost_per_inline_link_click,
            EntrySpend: entry.cost_per_inline_link_click,
          });

          fbDaily[date].spend =
            parseFloat((entry.spend || 0).toString()) +
            parseFloat((fbDaily[date].spend || 0).toString());

          fbDaily[date].cost_per_inline_link_click =
            (parseFloat((entry.cost_per_inline_link_click || 0).toString()) +
              parseFloat(
                (fbDaily[date].cost_per_inline_link_click || 0).toString()
              )) /
            selectedCampaigns.length;

          console.log({
            SpendAfterAdd: fbDaily[date].cost_per_inline_link_click,
          });
        }
      });
    });

    if (needsConversion === "USD") {
      const fetchExchangeRate = async (): Promise<number> => {
        try {
          const exchangeRateResponse: AxiosResponse<number> =
            await AetherService.get(
              `${backendBaseUrl}/exchange-rate/usd-to-sek`
            );

          console.log("Exchange rate response", exchangeRateResponse);
          return exchangeRateResponse.data;
        } catch (error) {
          console.warn("Failed to fetch exchange rate from DB", error);
          return 1;
        }
      };

      const exchangeRate: number = await fetchExchangeRate();
      console.log("Exchange rate", exchangeRate);
      Object.keys(fbDaily).forEach((date) => {
        fbDaily[date].spend *= exchangeRate;
        fbDaily[date].cost_per_inline_link_click *= exchangeRate;
      });
      console.log("fbDaily after exchange rate", fbDaily);
    }

    const mergedData: Record<string, MergedData> = {};

    shopifyData.forEach((entry) => {
      const date = entry.date;
      mergedData[date] = mergedData[date] || {
        revenue: 0,
        spend: 0,
        cost_per_inline_link_click: 0,
        numberOfOrders: 0,
      };
      mergedData[date].revenue = entry.revenue + mergedData[date].revenue;
      mergedData[date].numberOfOrders = entry.numberOfOrders;
      if (fbDaily[date]) {
        mergedData[date].spend = fbDaily[date].spend;
        mergedData[date].cost_per_inline_link_click =
          fbDaily[date].cost_per_inline_link_click;
      }
    });

    const results: Result[] = [];

    for (const date in mergedData) {
      const data = mergedData[date];
      const revenue = parseInt(data.revenue.toString());
      const adcost = parseInt(data.spend.toString());
      const cpc = parseFloat(
        parseFloat(data.cost_per_inline_link_click.toString()).toFixed(2)
      );
      const profit = revenue - adcost;
      const numClicks = adcost / cpc;

      let cvr;
      if (!numClicks || isNaN(numClicks)) {
        cvr = 0;
      } else {
        cvr = parseFloat(((data.numberOfOrders / numClicks) * 100).toFixed(2));
      }
      results.push({
        date,
        adcost: adcost,
        cpc: cpc,
        revenue: revenue,
        profit: parseFloat(profit.toFixed(2)),
        cvr: cvr,
      });
    }
    console.log("RESULTS after calc", results);
    return results.sort(
      (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
    );
  }

  const handleCampaignChange = (updatedClickedCampaigns: Array<string>) => {
    setSelectedCampaigns(updatedClickedCampaigns);
  };

  const brandId = id;

  const getShopifyData = async () => {
    try {
      const response = await AetherService.get(`/shopify/data/${brandId}`);
      console.log("SHOPIFY DATA ", response);
      setShopifyConnected(true);
      console.log("shopify data", response.data);
      setShopifyData(response.data);
      return response.data;
    } catch (error) {
      setShopifyConnected(false);
      console.warn("Shopify not connected", error);
    }
  };

  const isValidStoreName = (storeName: string): boolean => {
    const allowedPattern = /^[a-z0-9_-]+$/i;
    const prohibitedSubstrings = [
      "http:",
      "https:",
      ".com",
      ".org",
      ".net",
      ".myshopify",
      "se",
      ".nu",
      ".shop",
      ".co",
      ".uk",
      ".de",
      ".fr",
      ".it",
      ".es",
      ".nl",
      ".be",
      ".dk",
      ".fi",
      ".no",
      ".pl",
      ".cz",
      ".gr",
      ".pt",
      ".hu",
      ".ro",
      ".ru",
      ".tr",
      ".ua",
      ".jp",
      ".cn",
      ".kr",
      ".in",
      ".au",
      ".nz",
      ".br",
      ".ar",
      ".mx",
      ".ca",
      ".us",
      ".id",
      ".my",
      ".sg",
      ".th",
      ".vn",
      ".ph",
      ".hk",
      ".tw",
      ".ae",
      ".sa",
      ".eg",
      ".il",
      ".za",
      ".ng",
      ".ke",
      ".gh",
    ];

    if (!allowedPattern.test(storeName)) {
      console.log("Failed allowedPattern check:", storeName);
      return false;
    }

    for (const substring of prohibitedSubstrings) {
      if (storeName.includes(substring)) {
        console.log("Failed prohibitedSubstrings check:", storeName, substring);
        return false;
      }
    }

    return true;
  };

  const isValidApiKey = (apiKey: string): boolean => {
    if (!apiKey.startsWith("shpat_")) {
      return false;
    }

    const substringAfterPrefix = apiKey.slice("shpat_".length);

    if (substringAfterPrefix.includes("shpat_")) {
      return false;
    }

    return true;
  };

  const handleShopifyConnect = async () => {
    const trimmedStoreName = storeName.trim();
    const trimmedApiKey = apiKey.trim();

    if (!trimmedStoreName) {
      setStoreNameError("Store name is required.");
      return;
    }

    if (!isValidStoreName(trimmedStoreName)) {
      setStoreNameError(
        "Invalid store name. Please enter a valid store name, see example above"
      );
      return;
    }

    setStoreNameError("");

    if (!trimmedApiKey) {
      setApiKeyError("API key is required.");
      return;
    }

    if (!isValidApiKey(trimmedApiKey)) {
      setApiKeyError(
        "Invalid API key format. Please ensure it starts with 'shpat_'."
      );
      return;
    }

    setApiKeyError("");

    try {
      await AetherService.post("/shopify/connection", {
        shop: storeName,
        apiKey,
        productId: brandId,
      });
      await getShopifyData();
      await setShowShopifyModal(false);

      if (connectedToFacebook) {
        await getAllData();
      }
    } catch (error) {
      console.error("Error creating Shopify connection:", error);
    } finally {
      setIsLoading(false);
    }
  };

  function getCampaignsFromFacebookData(
    facebookData: FacebookCampaign[]
  ): string[] {
    const campaigns = new Set<string>(); // Use a Set to ensure uniqueness

    facebookData.forEach((campaignGroup) => {
      campaignGroup.data.forEach((entry) => {
        campaigns.add(entry.campaign_name);
      });
    });

    return Array.from(campaigns);
  }

  useEffect(() => {
    setIsLoading(true);
    getAllData();
  }, [brandId]);

  useEffect(() => {
    const fetchAndSetGraphData = async () => {
      const calculatedGraphData = await calculateMetrics(
        shopifyData,
        facebookData,
        ActiveSelectedCampaigns
      );
      console.log("CALCULATED GRAPH DATA", calculatedGraphData);
      setGraphData(calculatedGraphData);
    };

    fetchAndSetGraphData().catch(console.error);
    console.log("Running...");
  }, [ActiveSelectedCampaigns, facebookData, shopifyData]);

  async function getAllData() {
    const sData = await getShopifyData();
    const fData = await getFacebookDataFromBackend();
    setShopifyData(sData);
    setFacebookData(fData);

    if (fData && fData.length > 0) {
      const allCampaigns: Array<string> = getCampaignsFromFacebookData(fData);
      setAllCampaigns(allCampaigns);
      setSelectedCampaigns(allCampaigns);
    }

    setIsLoading(false);
  }

  // CHANGED TO AETHER
  const getFacebookDataFromBackend = async () => {
    try {
      const axiosResponse: AxiosResponse = await AetherService.get(
        `${backendBaseUrl}/facebook/data/${brandId}`
      );

      setConnectedToFacebook(true);
      console.log("FACEBOOK DATA:", axiosResponse.data);
      console.log(axiosResponse);
      setFacebookData(axiosResponse.data);
      return axiosResponse.data;
    } catch (error) {
      setConnectedToFacebook(false);
      console.warn("Facebook not connected", error);
    }
  };

  const responseFacebook = async (response: any) => {
    setIsLoading(true);
    if (response.accessToken) {
      console.log(
        "User has granted permissions and obtained access token:",
        response.accessToken
      );
      await sendAccessTokenToBackend(response.accessToken);
      await getFacebookDataFromBackend();
      setConnectedToFacebook(true);
      if (shopifyConnected) {
        await getAllData();
      } else {
        setIsLoading(false);
      }
    } else {
      console.log(
        "User cancelled the login process or did not grant permissions."
      );
      setConnectedToFacebook(false);
      setIsLoading(false);
    }
  };

  // CHANGED TO AETHER
  const sendAccessTokenToBackend = async (token: string) => {
    try {
      const axiosResponse: AxiosResponse = await AetherService.post(
        `${backendBaseUrl}/facebook/accessToken`,
        {
          facebookAccessToken: token,
          productId: brandId,
        }
      );
      // Handle the response from the backend if needed
      console.log(axiosResponse.data);
    } catch (error) {
      console.error("Error sending access token to backend:", error);
    }
  };

  if (!id) return null;

  return (
    <Container>
      <Content>
        <HeroText>{getBrandName(id) || ""}</HeroText>
        <Title>Analytics</Title>

        {!(shopifyConnected && connectedToFacebook) && (
          <AnalyticsText>
            From writing product descriptions and creating ad copy variations to
            keeping track of P&L and helping you brainstorm your next winning
            creative - Let AI build your next brand.
          </AnalyticsText>
        )}
        {!isLoading && (
          <>
            {shopifyConnected && !connectedToFacebook && (
              <div
                style={{
                  marginBottom: "20px",
                  padding: "20px",
                  background: "rgba(0,155,0,0.2)",
                  borderRadius: "10px",
                  lineHeight: "24px",
                }}
              >
                <b style={{ fontWeight: "700" }}>
                  Shopify has been connected successfully!
                </b>
                <br /> Please connect Facebook to see your Shop CPC, CVR,
                Adspend, Revenue and Profit.
              </div>
            )}
            {!shopifyConnected && connectedToFacebook && (
              <div
                style={{
                  marginBottom: "20px",
                  padding: "20px",
                  background: "rgba(0,155,0,0.2)",
                  borderRadius: "10px",
                  lineHeight: "24px",
                }}
              >
                <b style={{ fontWeight: "700" }}>
                  Facebook has been connected successfully!
                </b>
                <br /> Please connect to Shopify to see your Shop CPC, CVR,
                Adspend, Revenue and Profit.
              </div>
            )}
          </>
        )}
        <ButtonsContainer>
          {isLoading && (
            <Container>
              <LoadingContainer>
                <Loader />
                <LoadingTextWarning>
                  Please, do not refresh your browser
                </LoadingTextWarning>
              </LoadingContainer>
            </Container>
          )}
          {!shopifyConnected && !isLoading && (
            <PurpleShopifyButton
              onClick={() => {
                if (
                  user.email === "isbergphilip@gmail.com" ||
                  user.email === "carlos@digitalpotential.se" ||
                  user.email === "alexbrandon2030@gmail.com"
                ) {
                  setShowShopifyModal(true);
                }
              }}
              style={
                user.email !== "liam.bergstroms@icloud.com"
                  ? { backgroundColor: "#818A9C" }
                  : {}
              }
            >
              Connect Shopify Store
            </PurpleShopifyButton>
          )}

          {!connectedToFacebook && !isLoading && (
            <FacebookLogin
              appId="614942037433257"
              onSuccess={responseFacebook}
              scope="ads_read"
              language="en_US"
              render={({ onClick }) => (
                <FacebookStyledButton
                  onClick={() => {
                    if (
                      (user.email === "isbergphilip@gmail.com" ||
                        user.email === "carlos@digitalpotential.se" ||
                        user.email === "alexbrandon2030@gmail.com") &&
                      typeof onClick === "function"
                    ) {
                      onClick();
                    }
                  }}
                  style={
                    user.email !== "liam.bergstroms@icloud.com"
                      ? { backgroundColor: "#818A9C" }
                      : {}
                  }
                >
                  Login With Facebook
                </FacebookStyledButton>
              )}
            />
          )}
        </ButtonsContainer>

        {showShopifyModal && (
          <>
            <Overlay />
            <ModalContainer className={showShopifyModal ? "visible" : ""}>
              <CloseButton onClick={() => setShowShopifyModal(false)}>
                x
              </CloseButton>
              <ShopifyConnectColumn>
                <ShopifyLogoImage />
                <Title>Connect Your Shopify Store</Title>
                <ConnectText>
                  Quickly set up your connection by following these steps:
                </ConnectText>

                <VideoContainer>
                  <ResponsiveIframe
                    src="https://www.youtube.com/embed/dQw4w9WgXcQ?si=UNSbpixeBvIlrB1c"
                    allow="autoplay; encrypted-media; fullscreen"
                    allowFullScreen
                    title="Shopify Connection Instruction"
                  />
                </VideoContainer>

                <ConnectText>
                  Enter your Shopify store URL and API key as shown in the
                  video.
                </ConnectText>
                <InstructionText>
                  Only enter the store name, not the full URL.
                  <br />
                  https:// <Highlight>MY-STORE-NAME</Highlight>.myshopify.com
                </InstructionText>
                <StoreNameInput
                  type="text"
                  value={storeName}
                  onChange={(e) => {
                    setStoreName(e.target.value);
                    setStoreNameError("");
                  }}
                  placeholder="MY-STORE-NAME"
                />

                <ApiKeyInput
                  placeholder="Your Shopify API Key"
                  value={apiKey}
                  onChange={(e) => {
                    setApiKey(e.target.value);
                    setApiKeyError("");
                  }}
                />
                {storeNameError && <ErrorText>{storeNameError}</ErrorText>}

                {apiKeyError && <ErrorText>{apiKeyError}</ErrorText>}
                <ButtonsContainerPopout>
                  <ShopifyStyledButton onClick={handleShopifyConnect}>
                    Connect
                  </ShopifyStyledButton>
                  <PurpleShopifyButton
                    onClick={() => setShowShopifyModal(false)}
                  >
                    Cancel
                  </PurpleShopifyButton>
                </ButtonsContainerPopout>
              </ShopifyConnectColumn>
            </ModalContainer>
          </>
        )}

        {shopifyConnected && connectedToFacebook && !isLoading && (
          <DetailsContainer>
            <HorizontalContainer>
              <LeftColumn>
                <HeroText>AI SUGGESTION</HeroText>
                <SuggestionTextContainer graphData={graphData} />
              </LeftColumn>

              <RightColumn>
                <CampaignSelection
                  selectedCampaigns={ActiveSelectedCampaigns}
                  handleCampaignChange={handleCampaignChange}
                  allCampaigns={allCampaigns}
                />
              </RightColumn>
            </HorizontalContainer>

            <Graph data={graphData} />
            <HorizontalContainer>
              <LeftColumn>
                <HeroText>CAMPAIGNS SHOWING IN THE GRAPH</HeroText>
                <AnalyticsText>
                  The list is sorted by best to worst performing campaigns:
                  <ol>
                    {ActiveSelectedCampaigns.map((campaign, index) => (
                      <li key={index}>{campaign}</li>
                    ))}
                  </ol>
                </AnalyticsText>
              </LeftColumn>
            </HorizontalContainer>
          </DetailsContainer>
        )}
      </Content>
    </Container>
  );
};
