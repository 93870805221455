import React, { useEffect, useState } from "react";
import { AetherService } from "../../services/AetherSerivce";
import AdyenCheckout from "@adyen/adyen-web";
import "@adyen/adyen-web/dist/adyen.css";
import { useNavigate, useLocation } from "react-router-dom";
import { SubscriptionPlanSummary } from "./Payment.styles";
import { useAuthStore } from "../../stores/Authentication/AuthStore";
import { useUserStore } from "../../stores/User/UserStore";

// Access the environment variable using 'process.env'
const environment = process.env.REACT_APP_ENVIRONMENT;

// Now, you can use the 'environment' variable in your code
let adyenEnvironment: string;
let adyenClientKey: string;

if (environment === "dev") {
  adyenEnvironment = "test";
  adyenClientKey = `${process.env.REACT_APP_AYDEN_TEST_CLIENT_KEY}`;
} else if (environment === "prod") {
  adyenEnvironment = "live";
  adyenClientKey = `${process.env.REACT_APP_AYDEN_PROD_CLIENT_KEY}`;
} else {
  // Fallback to test
  adyenEnvironment = "test";
  adyenClientKey = `${process.env.REACT_APP_AYDEN_TEST_CLIENT_KEY}`;
}

const Payment: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [planSummary, setPlanSummary] = useState("");
  const [hasActiveSubscription, setHasActiveSubscription] =
    useState<boolean>(false);
  const [hasAlreadyHadTrial, setHasAlreadyHadTrial] = useState<boolean>();
  const { isAuthenticated } = useAuthStore();
  const { user } = useUserStore();

  const redirectToDashboard = async () => {
    const { data } = await AetherService.get("/user/product/all");

    if (data.length > 0) {
      // Assuming you have the necessary ID and feature values available
      const id = data.reverse()[0]._id;

      // LOCALSTORAGE NOT ALLOWED IN SHOPIFY TERMS:
      // localStorage.setItem("selectedSection", "product-description");

      navigate(`/dashboard/${id}/product-description`);
    } else {
      navigate({ pathname: "/dashboard" });
    }
  };

  const checkSubscriptionStatus = async (subscriptionTypeParam: string) => {
    if (
      user.email === "alexbrandon2030@gmail.com" ||
      user.email === "liam.bergstroms@icloud.com"
    )
      return;
    try {
      const response = await AetherService.get(
        "/adyen-payments/active-subscription"
      );
      const data = response.data;
      setHasActiveSubscription(data.isActive);
      setHasAlreadyHadTrial(data.hasHadFreeTrial);

      let coreText;
      if (subscriptionTypeParam.includes("yearly")) {
        coreText = "Yearly Plan - Full Brandroid Access";
      } else {
        coreText = "Monthly Plan - Full Brandroid Access";
      }
      setPlanSummary(coreText);

      // Change Payment button text if resubscribing.
      if (!data.hasHadFreeTrial) {
        // TODO: Make enums/backend endpoint for prices if scaling project
        const additionalTrialText = subscriptionTypeParam.includes("yearly")
          ? " (14 Days FREE then $349.99/year)"
          : " (14 Days FREE then $39.99/month)";
        coreText += additionalTrialText;
        setPlanSummary(coreText);
      } else {
        const additionalText = subscriptionTypeParam.includes("yearly")
          ? " ($349.99/year)"
          : " ($39.99/month)";
        coreText += additionalText;
        setPlanSummary(coreText);
        setTimeout(() => {
          var styleElem = document.head.appendChild(
            document.createElement("style")
          );
          styleElem.innerHTML = `.adyen-checkout__button__content::after {content: "Resubscribe to Brandroid${additionalText}"!important;}`;
        }, 1500);
      }

      return data.hasHadFreeTrial;
    } catch (error) {
      console.error("Error fetching subscription status:", error);
    }
  };

  // 1. initiate component
  useEffect(() => {
    if (isAuthenticated) {
      setIsLoading(true);
      // Get the search query string from the URL
      const { search } = location;

      // Parse the query string to extract query parameters
      const params = new URLSearchParams(search);
      const subscriptionTypeParam = params.get("subscriptionType") || "";
      checkSubscriptionStatus(subscriptionTypeParam);
    }
  }, [isAuthenticated]);

  // 2. initiate checkout after subscriptionstatus set
  useEffect(() => {
    console.log({ hasAlreadyHadTrial });
    // Ensure that we have the necessary information from checkSubscriptionStatus
    if (hasAlreadyHadTrial !== undefined) {
      initiateComponent();
    }
  }, [hasAlreadyHadTrial]);

  // 3. Redirect user if already has a active subscription.
  useEffect(() => {
    if (hasActiveSubscription) {
      redirectToDashboard();
    }
  }, [hasActiveSubscription]);

  async function initiateComponent() {
    // Get the search query string from the URL
    const { search } = location;
    console.log({ search });

    // Parse the query string to extract query parameters
    const params = new URLSearchParams(search);
    const subscriptionTypeParam = params.get("subscriptionType");

    // Step 1: initiate payment session
    const fetchPaymentSession = async () => {
      try {
        const response = await AetherService.post("/adyen-payments", {
          subscriptionType: subscriptionTypeParam,
          hasHadFreeTrial: hasAlreadyHadTrial,
        });
        return { success: true, data: response.data };
      } catch (e) {
        console.error("ERROR PAYMENTS");
        return { success: false, data: {} };
      }
    };

    // Step 2: attempt 3d secure payment
    const attempt3DSecurePayment = async (
      paymentMethod: Object,
      browserInfo: Object
    ) => {
      try {
        const response = await AetherService.post(
          "/adyen-payments/attempt-3d-secure-payment",
          {
            paymentMethod: paymentMethod,
            browserInfo: browserInfo,
            subscriptionType: subscriptionTypeParam,
            hasHadFreeTrial: hasAlreadyHadTrial,
          }
        );
        return { success: true, data: response.data };
      } catch (e) {
        console.error("ERROR PAYMENTS");
        return { success: false, data: {} };
      }
    };

    // Step 3: finalize 3d secure payment
    const finalize3DSecurePayment = async (redirectResult: string) => {
      try {
        const response = await AetherService.post(
          "/adyen-payments/finalize-3d-secure-payment",
          {
            redirectResult: redirectResult,
          }
        );
        return { success: true, data: response.data };
      } catch (e) {
        console.error("ERROR PAYMENTS");
        return { success: false, data: {} };
      }
    };

    // Step 4: Create subscription after purchase
    const createSubscriptionAfterFinalize = async (
      additionalData: Object,
      subscriptionType: string,
      pspReference: string
    ) => {
      try {
        const response = await AetherService.post(
          "/adyen-payments/create-subscription",
          {
            hasHadFreeTrial: hasAlreadyHadTrial,
            additionalData: additionalData,
            subscriptionType: subscriptionType,
            pspReference: pspReference,
          }
        );
        return { success: true, data: response.data };
      } catch (e) {
        console.error("ERROR PAYMENTS");
        return { success: false, data: {} };
      }
    };

    // After 3D Secure:
    const redirectResultParam = params.get("redirectResult");
    if (redirectResultParam) {
      const finalizeResponse = await finalize3DSecurePayment(
        redirectResultParam
      );

      const paymentFinalizedSuccessfully =
        finalizeResponse.data.resultCode === "Authorised";

      if (paymentFinalizedSuccessfully) {
        const additionalData = finalizeResponse.data.additionalData;
        const subscriptionType = finalizeResponse.data.merchantReference;
        const pspReference = finalizeResponse.data.pspReference;

        const createSubscriptionResponse =
          await createSubscriptionAfterFinalize(
            additionalData,
            subscriptionType,
            pspReference
          );
        if (!createSubscriptionResponse.success) {
          console.error(
            "Subscription Creation failed, contact support IMMEDIATELY"
          );
          navigate({
            pathname: "/dashboard/failed",
            search: location.search,
          });
        } else {
          // Payment Success:
          navigate({
            pathname: "/dashboard/success",
            search: location.search,
          });
          return;
        }
      } else {
        console.error("Payment finalize failed");
        navigate({
          pathname: "/dashboard/failed",
          search: location.search,
        });
      }

      return; // DO NOT INITIATE CHECKOUT AFTER 3D SECURE
    }

    const createAdyenCheckout = async () => {
      // Your Adyen API key and other configuration options
      const adyenConfig = {
        setStatusAutomatically: true,
        locale: "en-US",
        environment: adyenEnvironment,
        clientKey: adyenClientKey,
        analytics: {
          enabled: true,
        },
        session: {
          id: "", // Will be set later
          sessionData: "", // Will be set later
        },
        onSubmit: async (state: any, dropin: any) => {
          const submitResponse = await attempt3DSecurePayment(
            state.data.paymentMethod,
            state.data.browserInfo
          );
          const action = submitResponse.data.action;
          const resultCode = submitResponse.data.resultCode;
          console.log({ submitResponse });

          const paymentAttemptRedirect =
            action && resultCode === "RedirectShopper";
          const paymentInstantAuthorised = resultCode === "Authorised";
          if (paymentAttemptRedirect) {
            // Payment Succeeded Case #1:
            // If payment needs extra 3D secure verification.
            // Redirect to Adyen 3d secure password input,
            // When done, run "finalize3dsecure" method,
            // Then, if successful finalize, create subscription.
            dropin.handleAction(action);
          } else if (paymentInstantAuthorised) {
            // Payment Succeeded Case #2:
            // If payment was INSTANTLY authorised, create subscription and redirect
            const additionalData = submitResponse.data.additionalData;
            const subscriptionType = submitResponse.data.merchantReference;
            const pspReference = submitResponse.data.pspReference;

            const createSubscriptionResponse =
              await createSubscriptionAfterFinalize(
                additionalData,
                subscriptionType,
                pspReference
              );
            if (!createSubscriptionResponse.success) {
              console.error(
                "Subscription Creation failed, contact support IMMEDIATELY"
              );
              navigate({
                pathname: "/dashboard/failed",
                search: location.search,
              });
            } else {
              // Payment Success:
              navigate({
                pathname: "/dashboard/success",
                search: location.search,
              });
              return;
            }
          } else {
            dropin.setStatus("error", {
              message: "3D Secure payment initation failed. Please try again.",
            });
            setTimeout(() => {
              // Refresh the page after navigating
              window.location.reload();
            }, 2000);
          }
        },
        onError: (error: any, dropin: any) => {
          dropin.setStatus("error", {
            message: "Payment failed. Please try again.",
          });
          setTimeout(() => {
            navigate({
              pathname: "/dashboard/payment",
              search: location.search,
            });
          }, 3000);
          console.error(error.name, error.message, error.stack, dropin);
        },
        paymentMethodsConfiguration: {
          card: {
            hasHolderName: true,
            holderNameRequired: true,
            name: "Credit or debit card",
            billingAddressRequired: true,
          },
        },
      };

      // Before 3D Secure - Initiate Session & Checkout:
      try {
        const sessionResponse = await fetchPaymentSession();
        if (sessionResponse.success) {
          const { id, sessionData } = sessionResponse.data;
          adyenConfig.session.id = id;
          adyenConfig.session.sessionData = sessionData;
        } else {
          console.error("Failed to fetch payment session:", sessionResponse);
        }
      } catch (error) {
        console.error("Error fetching payment session:", error);
      }

      // Create an instance of the AdyenCheckout
      const checkout = await AdyenCheckout(adyenConfig);

      // User has been redirected after successful 3DS Security Check, and now needs to resubmit checkout
      // if (!redirectResultParam) {
      // User is viewing the payment form for the very first time,
      checkout.create("dropin").mount("#dropin-container");
      // }
      setIsLoading(false);
    };
    createAdyenCheckout();
  }

  return (
    <div>
      {!isLoading && (
        <SubscriptionPlanSummary>
          {!hasAlreadyHadTrial && (
            <b style={{ color: "#fff", fontWeight: "900" }}>14 Day Trial: </b>
          )}
          {planSummary}
        </SubscriptionPlanSummary>
      )}
      <div id="dropin-container"></div>
    </div>
  );
};

export default Payment;
