import styled from "styled-components"
import { EditTextarea } from 'react-edit-text';

export const Container = styled.div`
    width: 100%;
    min-height: 100%;
    padding: 1rem;
    display: flex;
    justify-content: center;
    margin-top: 2rem;
    
    @media (min-width: 1568px) { 
        margin-top: 6rem;
    }
`;

export const Content = styled.div`
    width: 100%;
    max-width:  900px;

    --primary-text: var(--third-text);
`

export const HeroText = styled.h1`
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    text-transform: uppercase;
    color: var(--brand-color-1);
`

export const Title = styled.h2`
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;

    margin-bottom: 1rem;
    color: var(--brand-color-2);
`

export const ContentContainer = styled.div`
    width: 100%;
    padding: 1rem;
    border-radius: 6px;
    background: var(--primary-white-text);
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
`

export const ContentText = styled.p`
    font-size: 14px;
    line-height: 24px;
    color: var(--brand-color-2);
`


export const StyledEditTextarea = styled(EditTextarea)<{ isEditing: boolean }>`
    min-height: 65vh;
    max-height: 65vh; // Set a maximum height
    font-size: 16px;
    line-height: 24px;
    border-radius: 6px;
    padding-right: 1rem;

    &:hover {
        background-color: #F7F8FE;
    }

   
    &::-webkit-scrollbar {
        width: 6px;
        background-color: #F5F5F5;
    }

    &::-webkit-scrollbar-thumb {
        background-color: var(--brand-color-1);
        border-radius: 3px;
    }
  `;
