type Props = {
    readonly size?: string
    readonly color?: string
}

export const Chevron = ({size = "24", color = "black"}: Props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" fill="none">
            <path 
                d="M6 9L12 15L18 9" 
                stroke={color}
                strokeWidth="2" 
                strokeLinecap="round" 
                strokeLinejoin="round"
            />
        </svg>
    )
}