import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import FailedSVG from '../../icons/Failed';
import { Container, SVGContainer, Message, Instructions, StyledButton } from './FailedPage.styles';

const FailedPage: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const handleButtonClick = () => {
        navigate({
            pathname: '/dashboard/payment',
            search: location.search,
        });
    };

    return (
        <Container>
            <SVGContainer>
                <FailedSVG />
            </SVGContainer>
            <Message>Payment Failed</Message>
            <Instructions>
                Unfortunately, there was an issue processing your transaction. Please try again.
            </Instructions>
            <StyledButton onClick={handleButtonClick}>Return to Payment</StyledButton>
        </Container>
    );
};

export default FailedPage;
