type Props = {
    color?: string
}

export const Checkmark = ({color = "var(--brand-color-1)"}: Props) => {
    return (
        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path 
                d="M8.00022 16.1386C3.78126 16.1386 0.361328 12.7187 0.361328 8.49973C0.361328 4.28077 3.78126 0.86084 8.00022 0.86084C12.2192 0.86084 15.6391 4.28077 15.6391 8.49973C15.6391 12.7187 12.2192 16.1386 8.00022 16.1386ZM7.23862 11.5553L12.6393 6.15383L11.5592 5.07369L7.23862 9.39501L5.07758 7.23397L3.99744 8.3141L7.23862 11.5553Z" 
                fill={color}
            />
        </svg>
    )
}