import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AetherService } from "../../services/AetherSerivce";
import { useBrandStoreStore } from "../../stores/Brands/BrandsStore";
import {
  Container,
  Content,
  HeroText,
  Title,
  StyledEditTextarea,
} from "./ProductDescription.styles";

import "react-edit-text/dist/index.css";
import { ToastContainer, toast } from "react-toastify";

import ToastCustomIcon from "../../icons/ToastCustomIcon";
import { ToastCustomMessage } from "../../atoms/ToastCustomMessage/ToastCustomMessage";
import { CloseIcon } from "../../icons/CloseIcon";

export const ProductDescription = () => {
  const { id } = useParams();
  const { getBrandName } = useBrandStoreStore();
  const [isEditing, setIsEditing] = useState(false);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [productDescription, setProductDescription] = useState<string>("");
  const [productDescriptionId, setProductDescriptionId] = useState<string>("");

  const handleEditMode = () => {
    console.log("Entering edit mode");
    setIsEditing(true);
  };

  const handleSave = async ({ value }: any) => {
    try {
      await AetherService.put("/product-description/" + productDescriptionId, {
        description: value,
      });
      toast.success(<ToastCustomMessage message="Saved your changes!" />, {
        className: "custom-toast-success",
        icon: <ToastCustomIcon />,
        closeButton: (
          <div style={{ margin: 0 }}>
            <CloseIcon size="12" color="var(--brand-color-1)" />
          </div>
        ),
      });
    } catch (e) {
      toast.error(
        "Failed to save your changes. Please copy and paste your text to another document."
      );
      console.error(e);
    }
  };

  useEffect(() => {
    const getProductDescription = async () => {
      try {
        const { data } = await AetherService.get(
          `/product/product-description/${id}`
        );
        const onlyShowOneDescriptionForNow = data[0].description;
        const prodDescId = data[0]._id;
        setProductDescriptionId(prodDescId);
        setProductDescription(onlyShowOneDescriptionForNow);
      } catch (err) {
        console.log(err);
      } finally {
        setIsLoading(false);
      }
    };

    getProductDescription();
  }, [id]);

  const editTextStyle = {
    minHeight: "65vh",
    maxHeight: "65vh",
    fontSize: "16px",
    lineHeight: "24px",
    borderRadius: "6px",
  };

  if (!id || isLoading) return null;

  return (
    <Container>
      <Content>
        <HeroText>{getBrandName(id) || ""}</HeroText>
        <Title>Product Description</Title>

        <StyledEditTextarea
          rows={20}
          onSave={handleSave}
          value={productDescription}
          onChange={(e) => setProductDescription(e.target.value)}
          onEditMode={handleEditMode}
          onBlur={() => setIsEditing(false)}
          isEditing={isEditing}
          style={editTextStyle}
        />
      </Content>
      <ToastContainer />
    </Container>
  );
};
