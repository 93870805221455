import styled from "styled-components";

export const SuggestionBoxContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%; /* Ensure the container fills its parent's width */
  border: none; /* Adjusted comment */
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column; /* Align children in a column */
  width: 100%; /* Ensure it takes the full width of its parent */
`;

export const SuggestionText = styled.p`
  color: #8195d5;
  word-wrap: break-word; /* Allows words to be broken and wrapped to the next line */
  max-heigh: 100%
  white-space: nowrap; /* Prevent wrapping at whitespace */
  max-width: 100%; /* Ensure it does not exceed the parent's width */
  font-family: "Inter", sans-serif;

  ol {
    display: flex;
    flex-direction: column;
    padding-inline-start 0px;
  }

  li {
    color: #8195d5;
    list-style-position: inside;
    margin-bottom: 0.5rem;
  }
`;
