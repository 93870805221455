import { StoreApi } from "zustand";
import { CreateProductStore_T } from "./CreateProductStore";

const validateLength = (value: string) => {
    return value.trim().length === value.length && value.trim().length
}

export const validateCreateProductInputs = (
    set: StoreApi<CreateProductStore_T>['setState'],
    get: StoreApi<CreateProductStore_T>['getState']) => {
    const { productText, featureText, benefitText, /*feelofcopyOption,*/ languageOption } = get()

    let product = false
    let feature = false
    let benefit = false
    let feel = false
    let language = false

    if (!validateLength(productText)) product = true

    if (!validateLength(featureText)) feature = true

    if (!validateLength(benefitText)) benefit = true

    // if (!validateLength(feelofcopyOption)) feel = true

    if (!validateLength(languageOption)) language = true

    set({
        CreateProductContentErrorState: {
            product,
            feature,
            benefit,
            feel,
            language,
        }
    })

    return !product && !feature && !benefit && !feel && !language
}