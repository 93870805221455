import styled from "styled-components"

export const Container = styled.div`
    width: 100%;
    height: 105px;
    background: var(--primary-white-text);
    background-color: var(--brand-color-1);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 4rem;
`

export const BrandroidLogo = styled.img`
    height: 37px;
`
export const WhiteBrandroidLogo = styled.img`
  height: 37px;
  filter: brightness(0) invert(1);
`;

export const NavigationSection = styled.div`
    display: flex;
    gap: 1rem;
`

export const Nav = styled.div`
    display: flex;
    align-items: center;
`

export const NavItem = styled.p`
    font-size: 16px;
    line-height: 24px;
    color: var(--primary-white-text);
    padding: 0.5rem 1.5rem;
`

export const UpgradeButton = styled.button`
    color: #1355FF;
    font-weight: 600;
    font-size: 14px;
    font-family: 'Montserrat', sans-serif;
    line-height: 24px;
    min-width: 165px;
    background: var(--primary-white-text);
    margin-left: 20px;
    // margin-right: 80px;
    // background: rgb(19,85,255);
    // background: linear-gradient(275deg, rgba(19,85,255,1) 0%, rgba(184,59,251,1) 100%);
    cursor: pointer;
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 6px;
`

export const Username = styled.h1`
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #fff;
`

export const UserContainer = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    gap: 1rem; 
    // transform: translateX(-10px);
`

export const UserDropdownContainer = styled.div`
    position: absolute;
    min-width: 100%;
    top: calc(100% +  0.5rem);
    right: 0;
    border-radius: 6px;
    background: var(--primary-white-text);
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
`

export const UserDropdownTopSection = styled.div`
    padding: 0.75rem;
`

export const UserDropdownSection = styled.div`
    padding: 0.75rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
`

export const UserDropdownText = styled.p`
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: var(--brand-color-1);
    
`

export const UserDropdownSubText = styled.p`
    font-size: 14px;
    line-height: 20px;
    color: var(--brand-color-2);
`

export const DropdownRow = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 0.5rem;
    cursor: pointer;
`

export const UserSectionDivider = styled.div`
    width: 100%;
    border-bottom: solid 1px var(--input-border-color);
`