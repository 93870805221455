
const ToastCustomIcon: React.FC = () => (
  <svg 
    width="26" 
    height="26" 
    viewBox="0 0 26 26" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
    style={{paddingLeft: 6}}
  >
    <path 
      d="M22.75 13C22.75 18.3848 18.3848 22.75 13 22.75C7.61522 22.75 3.25 18.3848 3.25 13C3.25 7.61522 7.61522 3.25 13 3.25C18.3848 3.25 22.75 7.61522 22.75 13Z" 
      fill="#1355FF" 
      stroke="#1355FF" 
      strokeWidth="4"
    />
    <path 
      d="M8.125 11.9167L11.9167 15.7084L18.1458 9.47925" 
      stroke="white" 
      strokeWidth="2"
    />
  </svg>
);

export default ToastCustomIcon;
