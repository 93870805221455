import styled from "styled-components";
import { Button } from "../../atoms/Button/Button";
import { Input } from "../../atoms/Input/Input";
import { Link } from "react-router-dom";

export const Container = styled.div`
  width: 100%;
  min-height: 100%;
  display: flex;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const ImageContainer = styled.div`
  width: 50%;
  height: 100vh;
  background: #f7f8fe;
  overflow: hidden;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const LoginContainer = styled.div`
  width: 50%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    width: 100%;
    height: auto;
  }
`;

export const LoginContent = styled.div`
  width: 70%;
  padding: 1rem;

  @media (max-width: 768px) {
    width: 100%;
  }
`;
export const Divider = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin: 2rem 0;
  color: var(--dashboard-background);

  div {
    flex-grow: 1;
    height: 1px;
    background-color: var(--dashboard-background);
  }

  span {
    margin: 0 1rem;
    font-size: 12px;
    font-weight: normal;
    color: #8195d5;
  }
`;

export const ConnectionButton = styled(Button)<{ children?: any }>`
  background-color: var(--dashboard-background);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 2rem;
  color: #160042;

  svg {
    margin-right: 8px;
  }
`;

export const StyledInput = styled(Input)`
  margin-top: 2.5rem;
`;

export const Title = styled.h1`
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  margin-top: 1rem;
  margin-bottom: 3.5rem;
  //remove when approved by facebook app review
`;

export const ActionTitle = styled.p`
  line-height: 24px;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 1.25px;
  color: var(--brand-color-1);
`;

export const LoginButton = styled(Button)`
  width: 100%;
  margin-top: 1.5rem;
`;

export const Subtitle = styled.p`
  line-height: 24px;
  font-size: 14px;
  margin-top: 13rem;
  // change margin-top to 15 rem when approved app from facebook
`;

export const SubtitleTwo = styled.p`
  line-height: 24px;
  font-size: 14px;
`;

export const SubtitleSpan = styled.span`
  font-weight: 500;
  color: var(--brand-color-1);
`;
export const StyledLink = styled(Link)`
  color: var(--brand-color-1);
  text-decoration: none;
`;
