import { OptionItem } from "./Select.styles"

type Props = {
    readonly value: string
    readonly onClick: (value: string) => void
}

export const SelectOption = ({value, onClick}: Props) => {
    return (
        <OptionItem 
            onClick={() => onClick(value)}>
            {value}
        </OptionItem>
    )
}