import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { AetherService } from "../../services/AetherSerivce";
import { useAuthStore } from "../../stores/Authentication/AuthStore";
import { AxiosError } from "axios";

import {
  ActionTitle,
  Container,
  ContentRow,
  ImageContainer,
  RegisterButton,
  RegisterContainer,
  RegisterContent,
  StyledInput,
  Subtitle,
  SubtitleSpan,
  Title,
  StyledLink,
  CheckboxContainer,
  StyledCheckbox,
  CheckboxLabel,
  CheckboxWrapper,
  CheckboxCheckmark,
} from "./Register.styles";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { z } from "zod";

const registrationSchema = z.object({
  firstname: z.string().min(1, "First name is required"),
  lastname: z.string().min(1, "Last name is required"),
  email: z.string().email("Invalid email address"),
  password: z
    .string()
    .min(8, "Password must be at least 8 characters")
    .regex(/[a-zA-Z]/, "Password must contain at least one letter")
    .regex(/\d/, "Password must contain at least one digit")
    .regex(
      /[!@#$%^&*(),.?":{}|<>]/,
      "Password must contain at least one special character"
    ),
});

export const Register = () => {
  const navigate = useNavigate();
  const { setIsAuth } = useAuthStore();

  const [firstname, setFirstname] = useState<string>("");
  const [lastname, setLastname] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [passwordErrors, setPasswordErrors] = useState<string[]>([]);

  const [isLoading, setLoading] = useState<boolean>(false);

  const [termsAccepted, setTermsAccepted] = useState<boolean>(false);
  const [hasAttemptedRegister, setHasAttemptedRegister] =
    useState<boolean>(false);

  const onRegister = async () => {
    if (isLoading) return;

    if (!termsAccepted) {
      setHasAttemptedRegister(true);
      return;
    }
    const result = registrationSchema.safeParse({
      firstname,
      lastname,
      email,
      password,
    });

    if (!result.success) {
      // Reset previous password errors
      setPasswordErrors([]);

      // Collect and separate password errors
      const newErrors = result.error.flatten();
      newErrors.fieldErrors.password
        ? setPasswordErrors(newErrors.fieldErrors.password)
        : setPasswordErrors([]);

      // Handle non-password errors with toasts
      const { password, ...otherErrors } = newErrors.fieldErrors;
      Object.values(otherErrors)
        .flat()
        .forEach((errorMsg) => toast.error(errorMsg));

      return;
    }

    // Clear password errors on successful validation
    setPasswordErrors([]);

    try {
      setLoading(true);
      console.log("logging in...");
      await AetherService.post("/user", {
        email,
        password,
        firstname,
        lastname,
      });

      const response = await AetherService.post("/auth/login", {
        email,
        password,
      });

      if (response.status === 200) {
        console.log(response.data.accessToken);
        setIsAuth(true, response.data.accessToken);

        navigate({
          pathname: "/dashboard/billing",
        });
      }
    } catch (error) {
      const err = error as AxiosError;

      if (err.isAxiosError && err.response) {
        if (err.response.status === 409) {
          toast.error(
            "This email is already in use. Please use a different email."
          );
        } else {
          toast.error("Registration error. Please try again later.");
        }
      } else {
        toast.error("An unexpected error occurred.");
      }
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      onRegister();
    }
  };

  return (
    <Container>
      <ImageContainer>{/* <Initial /> */}</ImageContainer>
      <RegisterContainer>
        <RegisterContent>
          <ActionTitle>SIGN UP</ActionTitle>
          <Title>Register</Title>

          <ContentRow>
            <StyledInput
              value={firstname}
              placeholder="Enter Name"
              onChange={(e) => setFirstname(e.target.value)}
              label="First Name"
            />
            <StyledInput
              value={lastname}
              placeholder="Enter Last Name"
              onChange={(e) => setLastname(e.target.value)}
              label="Last Name"
            />
          </ContentRow>

          <StyledInput
            value={email}
            placeholder="Enter Email"
            onChange={(e) => setEmail(e.target.value)}
            label="Email Adress"
            type="email"
          />
          <StyledInput
            value={password}
            placeholder="Enter Password"
            onChange={(e) => setPassword(e.target.value)}
            label="Password"
            type="password"
            onKeyDown={handleKeyPress}
          />

          {passwordErrors.map((error, index) => (
            <p
              key={index}
              style={{ color: "red", marginTop: "5px", fontSize: "14px" }}
            >
              {error}
            </p>
          ))}

          <CheckboxContainer>
            <StyledCheckbox
              id="termsCheckbox"
              checked={termsAccepted}
              onChange={() => {
                setTermsAccepted(!termsAccepted);
                if (!termsAccepted) {
                  setHasAttemptedRegister(false);
                }
              }}
              hasAttemptedRegister={hasAttemptedRegister}
            />
            <CheckboxWrapper hasAttemptedRegister={hasAttemptedRegister}>
              <CheckboxCheckmark checked={termsAccepted} />
            </CheckboxWrapper>

            <CheckboxLabel htmlFor="termsCheckbox">
              I agree to the{" "}
              <a
                href="https://www.brandroid.ai/terms-and-conditions"
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms
              </a>{" "}
              and{" "}
              <a
                href="https://www.brandroid.ai/privacy-policy"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>
            </CheckboxLabel>
          </CheckboxContainer>

          <RegisterButton onClick={onRegister}>Register</RegisterButton>
          <Subtitle>
            Already have an account?{" "}
            <SubtitleSpan>
              <StyledLink to={"/"}>Login</StyledLink>
            </SubtitleSpan>
          </Subtitle>
          <ToastContainer />
        </RegisterContent>
      </RegisterContainer>
    </Container>
  );
};
