import React, { useEffect, useMemo, useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import CustomTooltip from "./CustomTooltip";
import { Button } from "../../atoms/Button/Button";

interface DataItem {
  date: string;
  cvr: number;
  revenue: number;
  profit: number;
  adcost: number;
  cpc: number;
}

interface GraphProps {
  data: DataItem[];
}

// Enum to represent the metric sets
enum MetricSet {
  RevenueAdcostProfit = "RevenueAdcostProfit",
  CvrCpc = "CvrCpc",
}

const getDate = (daysAgo: number): string => {
  const today = new Date();
  today.setDate(today.getDate() - daysAgo - 1);

  const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const dayOfWeek = daysOfWeek[today.getDay()];
  const dayOfMonth = today.getDate();

  return `${dayOfWeek} ${dayOfMonth}`;
};

const renderLegend = (props: any) => {
  const { payload } = props;

  const formatLegendText = (value: string) => {
    switch (value) {
      case "cvr":
        return `${value} (%)`;
      case "cpc":
        return `${value} (SEK per click)`;
      default:
        return `${value} (SEK)`;
    }
  };

  return (
    <ul style={{ textAlign: "center", paddingBottom: 10, margin: 0 }}>
      {payload.map((entry: any, index: number) => (
        <li
          key={`item-${index}`}
          style={{
            listStyle: "none",
            display: "inline-block",
            marginRight: "10px",
          }}
        >
          <svg
            width="15"
            height="15"
            viewBox="0 0 32 32"
            style={{ marginRight: "5px", verticalAlign: "middle" }}
          >
            <rect width="15" height="15" fill={entry.color} />
          </svg>
          <span
            style={{ verticalAlign: "top", fontSize: "14px", color: "#8195d5" }}
          >
            {formatLegendText(entry.value)}
          </span>
        </li>
      ))}
    </ul>
  );
};

export const Graph: React.FC<GraphProps> = ({ data }) => {
  const [currentMetricSet, setCurrentMetricSet] = useState<MetricSet>(
    MetricSet.RevenueAdcostProfit
  );

  // Toggle function
  const toggleMetricSet = () => {
    setCurrentMetricSet(
      currentMetricSet === MetricSet.RevenueAdcostProfit
        ? MetricSet.CvrCpc
        : MetricSet.RevenueAdcostProfit
    );
  };
  // Preprocess the data to add a 'date' field to each data point
  const processedData = useMemo(() => {
    return data.map((item, index) => ({
      ...item,
      date: getDate(data.length - 1 - index), // Calculate the date based on the index
    }));
  }, [data]);

  // Log the processed data to the console
  useEffect(() => {
    console.log(processedData);
  }, [processedData]);

  const chartSize = useMemo(() => {
    const width = window.innerWidth;
    return {
      height: width < 768 ? 250 : 380,
      margin: {
        top: 5,
        right: 0,
        bottom: 0,
        left: 0,
      },
    };
  }, []);

  return (
    <>
      <Button onClick={toggleMetricSet} style={{ marginBottom: "20px" }}>
        Toggle Metrics
      </Button>
      <ResponsiveContainer width="100%" height={chartSize.height}>
        <LineChart data={processedData} margin={chartSize.margin}>
          <CartesianGrid
            stroke="#8195d5"
            vertical={false}
            strokeOpacity={0.2}
          />
          <XAxis
            dataKey="date"
            tick={{
              fontSize: 16,
              fill: "#8195d5",
              fontFamily: "Inter, sans-serif",
            }}
            interval={0}
            strokeWidth={0}
            padding={{ left: 30, right: 30 }}
            fontFamily="Inter, sans-serif"
          />
          <Tooltip content={<CustomTooltip />} />
          <Legend content={renderLegend} verticalAlign="top" align="center" />
          {currentMetricSet === MetricSet.RevenueAdcostProfit && (
            <>
              <Line
                type="natural"
                dataKey="revenue"
                stroke="#5061FF"
                strokeWidth={2}
                dot={{ fill: "#5061FF", strokeWidth: 2 }}
              />
              <Line
                type="natural"
                dataKey="adcost"
                stroke="#5CE1E6"
                strokeWidth={2}
                dot={{ fill: "#5CE1E6", strokeWidth: 2 }}
              />
              <Line
                type="natural"
                dataKey="profit"
                stroke="#43A5FF"
                strokeWidth={2}
                dot={{ fill: "#43A5FF", strokeWidth: 2 }}
              />
            </>
          )}
          {currentMetricSet === MetricSet.CvrCpc && (
            <>
              <Line
                type="natural"
                dataKey="cpc"
                stroke="#8A50FF"
                strokeWidth={2}
                dot={{ fill: "#8A50FF", strokeWidth: 2 }}
              />
              <Line
                type="natural"
                dataKey="cvr"
                stroke="#A1EBFF"
                strokeWidth={2}
                dot={{ fill: "#A1EBFF", strokeWidth: 2 }}
              />
            </>
          )}
        </LineChart>
      </ResponsiveContainer>
    </>
  );
};

export default Graph;
