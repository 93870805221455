import { useCreateProductStore } from "../../../stores/CreateProduct/CreateProductStore";
import { useUserStore } from "../../../stores/User/UserStore";
import {
  Container,
  Content,
  HeroTitle,
  SelectSection,
  StyledButton,
  StyledInput,
  StyledSelect,
  Subtitle,
  Title,
} from "../CreateProduct.styles";

type Props = {
  onClickGenerate: () => Promise<void>;
};

export const CreateProductContent = ({ onClickGenerate }: Props) => {
  const { user } = useUserStore();
  const {
    productText,
    featureText,
    benefitText,
    // feelofcopyOption,
    languageOption,
    setProductText,
    setFeatureText,
    setBenefitText,
    // setFeelofcopyOption,
    setLanguageOption,
    CreateProductContentErrorState,
    validateCreateProductInputs,
  } = useCreateProductStore();

  const onGenerateValidation = () => {
    if (validateCreateProductInputs()) onClickGenerate();
  };

  return (
    <Container>
      <Content>
        <HeroTitle>START BUILDING</HeroTitle>
        <Title>
          Build brands <span className="highlight">faster</span> and easier than
          ever.
        </Title>
        <Subtitle>Let AI help you build brands from start to finish</Subtitle>

        <StyledInput
          value={productText}
          onChange={(e) => setProductText(e.target.value)}
          label="What's your product?"
          placeholder="e.g. A mini projector"
          hasError={CreateProductContentErrorState.product}
        />
        <StyledInput
          value={featureText}
          onChange={(e) => setFeatureText(e.target.value)}
          label="What's your main feature?"
          placeholder="e.g. Displays high quality image indoors, without the trouble of a screen."
          hasError={CreateProductContentErrorState.feature}
        />
        <StyledInput
          value={benefitText}
          onChange={(e) => setBenefitText(e.target.value)}
          label="What's your main benefit?"
          placeholder="e.g. People may enjoy a cozy indoor cinematic experience at home during dark times."
          hasError={CreateProductContentErrorState.benefit}
        />

        <SelectSection>
          {/* <StyledSelect
                        value={feelofcopyOption}
                        onSelectOption={(value) => setFeelofcopyOption(value)}
                        label="Feel of copy" 
                        options={["Happy"]}
                        hasError={CreateProductContentErrorState.feel}
                    /> */}
          {user.email === "isbergphilip@gmail.com" ||
          user.email === "tester@test.com" ||
          user.email === "alexbrandon2030@gmail.com" ? (
            <StyledSelect
              value={languageOption}
              onSelectOption={(value) => setLanguageOption(value)}
              label="Preferred Language"
              options={["English", "Swedish"]}
              hasError={CreateProductContentErrorState.language}
            />
          ) : (
            <StyledSelect
              value={languageOption}
              onSelectOption={(value) => setLanguageOption(value)}
              label="Preferred Language"
              options={["English"]}
              hasError={CreateProductContentErrorState.language}
            />
          )}
        </SelectSection>

        <StyledButton onClick={onGenerateValidation}>Create Brand</StyledButton>
      </Content>
    </Container>
  );
};
