import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { AetherService } from "../../services/AetherSerivce";
import { useAuthStore } from "../../stores/Authentication/AuthStore";
import {
  ActionTitle,
  Container,
  ImageContainer,
  LoginButton,
  LoginContainer,
  LoginContent,
  StyledInput,
  Subtitle,
  SubtitleSpan,
  Title,
  StyledLink,
  SubtitleTwo,
} from "./Login.styles";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import Cookies from "js-cookie"
import { useUserStore } from "../../stores/User/UserStore";
export const Login = () => {
  const navigate = useNavigate();
  const { user } = useUserStore();

  const { setIsAuth } = useAuthStore();

  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const [isLoading, setLoading] = useState<boolean>(false);

  const onLogin = async () => {
    if (isLoading) return;

    try {
      setLoading(true);

      const response = await AetherService.post("/auth/login", {
        email,
        password,
      });

      console.log({ response });

      // OLD UNUSED COOKIE
      // console.log("Response Headers:", response.headers);
      // const cookie = Cookies.get("AetherToken");
      // console.log({ cookie })
      // // Check if the Set-Cookie header is present
      // if (response.headers.hasOwnProperty('set-cookie')) {
      //     const setCookieHeader = response.headers['set-cookie'];
      //     console.log("Set-Cookie Header:", setCookieHeader);
      // }

      const onClickLogin = async () => {
        const { data } = await AetherService.get("/user/product/all");

        if (data.length > 0) {
          // Assuming you have the necessary ID and feature values available
          const id = data.reverse()[0]._id;

          // LOCALSTORAGE NOT ALLOWED IN SHOPIFY TERMS:
          // localStorage.setItem("selectedSection", "product-description");

          navigate(`/dashboard/${id}/product-description`);
        } else {
          // Handle the case where there are no products, navigating to create page or billing as needed
          // For Facebook/Shopify Account Review liam.bergstrom@...
          if (
            user.email === "alexbrandon2030@gmail.com" ||
            user.email === "liam.bergstroms@icloud.com"
          ) {
            navigate({ pathname: "/dashboard/create" });
          } else {
            navigate({ pathname: "/dashboard/billing" });
          }
        }
      };

      console.log(response);
      if (response.status === 200) {
        console.log(response.data.accessToken);
        setIsAuth(true, response.data.accessToken);
        await onClickLogin();
      }
    } catch (err) {
      toast.error("Login error");
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      onLogin();
    }
  };

  if (isLoading) {
    return <div> Loading... </div>;
  } else {
    return (
      <Container>
        <ImageContainer>{/* <Initial /> */}</ImageContainer>
        <LoginContainer>
          <LoginContent>
            <ActionTitle>LOGIN</ActionTitle>
            <Title>Welcome Back!</Title>

            {/* Comment buttons + divider back when approved from Facebook app review team */}

            {/* <ButtonsContainer> 
                        <ConnectionButton> Google  </ConnectionButton>
                       
                        <ConnectionButton>
                        <FacebookIcon />
                             Facebook
                        </ConnectionButton>
                        </ButtonsContainer>

                        <Divider>
                            <div></div>
                            <span>or</span>
                            <div></div>
                        </Divider> */}

            <StyledInput
              value={email}
              placeholder="Enter Email"
              onChange={(e) => setEmail(e.target.value)}
              label="Email address"
            />
            <StyledInput
              value={password}
              placeholder="Enter Password"
              onChange={(e) => setPassword(e.target.value)}
              label="Password"
              type="password"
              onKeyDown={handleKeyPress}
            />
            <LoginButton onClick={onLogin}>Login</LoginButton>
            <Subtitle>
              Don't have an account?{" "}
              <SubtitleSpan>
                <StyledLink to={"/register"}>Register</StyledLink>
              </SubtitleSpan>
            </Subtitle>
            <SubtitleTwo>
              Did you forget your password?{" "}
              <SubtitleSpan>
                <StyledLink to={"/forgot-password"}>Reset password</StyledLink>
              </SubtitleSpan>
            </SubtitleTwo>
            <ToastContainer />
          </LoginContent>
        </LoginContainer>
      </Container>
    );
  }
};
