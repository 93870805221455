// Analytics.styles.tsx
import styled, { keyframes } from "styled-components";
import { StyledInput } from "../../atoms/Input/Input.styles";

export const Content = styled.div`
  width: 100%;
  max-width: 900px;
`;

export const HeroText = styled.h1`
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  text-transform: uppercase;
  color: var(--brand-color-1);
  letter-spacing: 0.08px;
  padding-top: 1.5rem;
`;

export const Title = styled.h2`
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;

  margin-bottom: 1rem;
  color: var(--brand-color-2);
`;

export const AnalyticsText = styled.div`
  width: 80%;
  margin-bottom: 1.5rem;
  margin-top: 0.8rem;
  font-size: 16px;
  line-height: 1.4;
  color: #8195d5;

  // New styles for the list inside AIIntroText
  ol li {
    max-height: 30px;
    margin: 0;
    padding-left: 10px;
    font: inherit;
    color: #8195d5;
    list-style-position: inside;
  }

  li {
    padding-top: 0.9rem;
  }

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

export const DetailsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const SelectedCampaignsList = styled.ul`
  list-style: none;
  padding: 0;
`;

export const SelectedCampaignItem = styled.li`
  background-color: #3b5998;
  color: white;
  padding: 5px;
  margin: 5px 0;
  font-weight: bold;
`;

export const LockInButton = styled.button`
  background-color: #3b5998;
  color: white;
  width: 100%;
  font-weight: bold;
  border: none;
  padding: 10px 20px;
  margin-top: 1.25rem;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;

  &:hover {
    background-color: #263f75;
  }
`;

export const HorizontalContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
  align-items: flex-start;
  padding-bottom: 20px;
`;

export const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%; /* Adjust the width as necessary */
`;

export const RightColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  width: 100%;
`;

export const SuggestionBoxContainer = styled.div`
  display: flex;
  align-items: center;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  border: 1px solid #ccc;
  padding: 10px;
`;

export const SuggestionText = styled.div`
  flex-grow: 1;
  word-wrap: break-word;
`;

export const AutoCompleteButton = styled.button`
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: #3b5998;
  color: white;
  font-weight: bold;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;

  &:hover {
    background-color: #263f75;
  }
`;

export const CharacterImage = styled.img`
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 10px;
`;

export const Box = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 0.625rem;
`;

export const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  // justify-content: center;
  align-items: center;
  gap: 20px;
  max-width: 800px;
`;

export const ShopifyStyledButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  background-color: #96bf48;
  color: white;
  width: 250px;
  max-width: 250px;
  padding: 19px 19px;
  border: none;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
  border-radius: 5px;

  &:hover {
    background-color: #6d9a40;
  }
`;

export const PurpleShopifyButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px; // Set a fixed height
  background-color: #160042;
  color: white;
  width: 250px;
  max-width: 250px;
  padding: 0 19px; // Adjusted padding for horizontal only
  border: none;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
  border-radius: 5px;

  &:hover {
    background-color: #10002e;
  }
`;

export const ShopifyConnectColumn = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`;

export const FacebookStyledButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  background-color: var(--brand-color-1);
  color: white;
  width: 250px;
  max-width: 250px;
  padding: 0 19px; /* Adjusted padding */
  border: none;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 1; /* Added line-height */
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
  border-radius: 5px;

  &:hover {
    background-color: #29487d;
  }
`;

export const ContentText = styled.p`
  font-size: 14px;
  line-height: 24px;
  color: var(--brand-color-2);
`;

export const EditTextArea = styled.p`
  font-size: 14px;
  line-height: 24px;
  color: var(--brand-color-2);
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  font-size: 20px;
  cursor: pointer;
`;

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(135, 158, 230, 0.5);
  z-index: 10;
  transition: opacity 0.3s ease-in;
`;

export const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
  margin-top: 5rem;
`;

export const LoadingTextAnim = keyframes`
    0% {
        opacity: 0;
        transform: translateY(100%);
    }
    100% {
        opacity: 1;
        transform: translateY(0%);
    }
`;
export const LoadingTextWarning = styled.p`
  font-size: 14px;
  color: var(--brand-color-2);
`;

export const LoadingText = styled.p`
  font-size: 14px;
  color: var(--brand-color-2);
  animation: ${LoadingTextAnim} 1s;
`;

// SHOPIFY OLD STYLES

// export const ConnectText = styled.p`
//   font-size: 16px; /* Adjust as needed */
//   text-align: center;
//   color: var(--brand-color-2);
//   max-width: 350px; /* Adjust as needed */
//   margin-bottom: 15px;

//   span {
//     color: #96bf48;
//   }
// `;

// export const ButtonsContainerPopout = styled.div`
//   width: 100%;
//   display: flex;
//   flex-direction: row;
//   justify-content: center;
//   align-items: center;
//   gap: 20px;
//   max-width: 800px;
// `;

// export const StoreNameInput = styled(StyledInput)`
//   font-size: 0.8rem;
//   padding: 0.5rem;
//   height: 2rem;
//   width: 8rem;
// `;

// export const ApiKeyInput = styled(StyledInput)`
//   font-size: 0.8rem;
//   padding: 0.5rem;
//   height: 2rem;
//   width: 8rem;
// `;

// export const ModalContainer = styled.div`
//   position: fixed;
//   top: 50%;
//   left: 50%;
//   transform: translate(-50%, -50%);
//   width: 70vw; // 70% of the viewport's width
//   height: 70vh; // 70% of the viewport's height
//   z-index: 1000;
//   background: #fff;
//   padding: 20px;
//   box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
//   transition: transform 0.3s ease-in, opacity 0.3s ease-in;
//   opacity: 0;
//   transform: translate(-50%, -60%); // Start a little above the center
//   overflow-y: auto; // To ensure the content inside is scrollable if it overflows

//   &.visible {
//     opacity: 1;
//     transform: translate(-50%, -50%);
//   }
// `;

// SHOPIFY NEW STYLES

// export const StoreNameInput = styled(StyledInput)`
//   font-size: 1rem; // Enhanced readability
//   padding: 0.75rem; // Improved touch area
//   margin-top: 10px; // Added spacing for visual separation
//   width: 100%; // Full width for responsiveness
//   border: 1px solid #ccc; // Defined input area
//   border-radius: 5px; // Softened edges

//   &:focus {
//     border-color: #96bf48; // Focus highlight
//     outline: none; // Clean focus state
//   }
// `;

export const ErrorText = styled.span`
  display: block;
  color: #d8000c;
  margin-top: 5px;
  font-size: 0.9rem;
`;

export const ConnectText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: var(--brand-color-2);
  margin-bottom: 15px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const UrlInputContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Highlight = styled.span`
  color: #96bf48;
  font-weight: bold;
`;

export const InstructionText = styled.p`
  text-align: center;
  margin-bottom: 0.7rem;
`;

// export const ButtonsContainerPopout = styled.div`
//   display: flex;
//   justify-content: space-around;
//   width: 100%;
//   margin-top: 20px;
//   gap: 20px;
// `;

// export const VideoContainer = styled.div`
//   width: 100%; // Use 100% to make it responsive
//   max-width: 900px; // Set a max-width to control the size on larger screens
//   aspect-ratio: 16 / 9; // Maintain a 16:9 aspect ratio
//   margin-bottom: 20px;

//   iframe {
//     width: 100%; // Make the iframe fill the container
//     height: 100%; // Adjust height based on the aspect ratio
//     border: none; // This removes the border visually
//   }
// `;

// export const ModalContainer = styled.div`
//   position: fixed;
//   top: 50%;
//   left: 50%;
//   transform: translate(-50%, -50%);
//   width: auto;
//   max-width: 800px;
//   padding: 2rem;
//   background: white;
//   border-radius: 8px;
//   box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
//   z-index: 1000;
//   overflow-y: auto;
// `;

export const StoreNameInput = styled(StyledInput)`
  font-size: 1rem;
  padding: 0.5rem;
  width: 60%;
  border: 1px solid #ccc;
  border-radius: 5px;
  text-align: center;

  &::placeholder {
    text-align: center;
  }

  &:focus {
    border-color: #96bf48;
    outline: none;
  }

  @media (max-width: 768px) {
    margin: 0; // Remove margin on small screens
    width: 90%; // Take up more width within the modal
    max-width: unset; // Allow the input to grow
  }
`;

export const ApiKeyInput = styled(StyledInput)`
  font-size: 1rem;
  padding: 0.5rem;
  width: 60%;
  border: 1px solid #ccc;
  border-radius: 5px;
  text-align: center;

  &::placeholder {
    text-align: center;
  }

  &:focus {
    border-color: #96bf48;
    outline: none;
  }

  @media (max-width: 768px) {
    margin-top: 0.5rem; // Reduce margin-top on small screens
    width: 90%; // Take up more width within the modal
    max-width: unset; // Allow the input to grow
  }
`;

// testing mobile responsiveness

export const VideoContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: 500px; // Adjust the max-width as needed
  margin: 0 auto; // This will center the video in the modal

  &:before {
    content: "";
    display: block;
    padding-top: 56.25%; // This sets the aspect ratio to 16:9
  }
`;

export const ResponsiveIframe = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
`;

export const ModalContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%; // Slightly smaller width for better mobile view
  max-width: 900px; // Maximum container width
  max-height: 90vh; // Maximum container height to ensure it's all visible on screen
  padding: 20px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  overflow-y: auto; // Scroll if the content is too tall
`;

export const ButtonsContainerPopout = styled.div`
  display: flex;
  flex-direction: column; // Stack buttons vertically
  align-items: stretch; // Stretch buttons to fill width
  gap: 10px; // Space between buttons

  @media (min-width: 768px) {
    flex-direction: row; // Side by side on larger screens
    justify-content: space-around;
    gap: 20px;
  }
`;
