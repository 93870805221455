import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AetherService } from "../../services/AetherSerivce";
import { CenteredContainer, Message } from "./Dashboard.styles";
import { DashboardIcon } from "../../icons/DashboardIcon";

import { Loader } from "../../atoms/Loader/Loader";

interface Brand {
  _id: string;
}

export const Dashboard = () => {
  const [brands, setBrands] = useState<Brand[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchBrands = async () => {
      setIsLoading(true);
      try {
        const { data } = await AetherService.get("/user/product/all");
        console.log("Fetched brands:", data); // Debugging: Inspect fetched data
        setBrands(data);
      } catch (err) {
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchBrands();
  }, []);

  useEffect(() => {
    if (!isLoading && brands.length > 0) {
      const latestBrandId = brands[0]._id;
      console.log("Navigating to brand ID:", latestBrandId); // Debugging: Inspect the ID being used for navigation

      if (latestBrandId) {
        // Ensure latestBrandId is truthy before navigating
        navigate(`/dashboard/${latestBrandId}/product-description`);
      } else {
        console.error("Brand ID is null or undefined."); // Helps identify the issue if _id is missing
      }
    }
  }, [brands, isLoading, navigate]);

  if (isLoading) {
    return (
      <CenteredContainer>
        <Loader />
      </CenteredContainer>
    );
  }

  if (!brands || brands.length === 0) {
    return (
      <CenteredContainer>
        <DashboardIcon />
        <Message>
          Oops.. Looks like you have no brands yet. Click on "Create new Brand"
          in the side navigation to the left.
        </Message>
      </CenteredContainer>
    );
  }
  return null;
};
