

type CloseIconProps = {
    size?: string; // optional size prop
    color?: string; // optional color prop
};

export const CloseIcon: React.FC<CloseIconProps> = ({ size = '17px', color = '#8195D5' }) => {
    return (
        <div 
            style={{ 
                cursor: 'pointer',
                margin: '8px 8px 0 0', // Adds 16px margin to the top and right
                display: 'flex', // Ensures the icon is properly aligned
                alignItems: 'center', // Centers the icon vertically
                justifyContent: 'center', // Centers the icon horizontally

            }}
        >
            {/* SVG with width, height, and fill properties set by props */}
            <svg 
                width={size} // Size passed via props or default size
                height={size} // Make sure the SVG is square or adjust as needed
                viewBox="0 0 13 16" // ViewBox preserved from the original SVG
                fill="none" 
                xmlns="http://www.w3.org/2000/svg"
            >
                <path 
                    d="M1.51617 15.18C1.10284 15.18 0.756172 15.04 0.476172 14.76L5.45617 7.78L0.736172 1.16C1.00284 0.933332 1.32951 0.819999 1.71617 0.819999C1.99617 0.819999 2.24951 0.873332 2.47617 0.979999C2.71617 1.08667 2.90284 1.23333 3.03617 1.42L6.39617 6.24L5.93617 6.86L6.07617 6.96L9.95617 1.42C10.0895 1.23333 10.2695 1.08667 10.4962 0.979999C10.7362 0.873332 10.9962 0.819999 11.2762 0.819999C11.4495 0.819999 11.6228 0.853332 11.7962 0.919999C11.9828 0.973332 12.1362 1.05333 12.2562 1.16L7.53617 7.78L12.5162 14.76C12.2362 15.04 11.8895 15.18 11.4762 15.18C11.2362 15.18 11.0095 15.12 10.7962 15C10.5962 14.8933 10.4228 14.74 10.2762 14.54L6.49617 9.18L2.71617 14.54C2.56951 14.74 2.38951 14.8933 2.17617 15C1.97617 15.12 1.75617 15.18 1.51617 15.18Z" 
                    fill={color} // Color passed via props or default color
                />
            </svg>
        </div>
    );
};
