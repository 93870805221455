// DashboardWrapper.js
import { useState, useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { TopNavigation } from "../molecules/TopNavigation/TopNavigation";
import { Container, OutletContainer } from "./DashboardWrapper.styles";
import { ProtectedRoute } from "./ProtectedRoute";
import { useUserStore } from "../stores/User/UserStore";
import { AetherService } from "../services/AetherSerivce";
import { UserMeResponse } from "../types/User_T";

export const DashboardWrapper = () => {
  const [isLoading, setLoading] = useState(true);
  const [hasError, setError] = useState(false);
  const { setUser } = useUserStore();

  useEffect(() => {
    const FetchUserResource = async () => {
      try {
        const { data } = await AetherService.get<UserMeResponse>(
          "/user/me/details"
        );
        setUser(data);
      } catch (err) {
        setError(true);
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    FetchUserResource();
  }, [setUser]);

  if (isLoading) return null;

  if (!isLoading && hasError) {
    return <Navigate to="/" />;
  }

  return (
    <ProtectedRoute>
      <Container>
        <TopNavigation />
        <OutletContainer>
          <Outlet />
        </OutletContainer>
      </Container>
    </ProtectedRoute>
  );
};
