import styled, { keyframes } from "styled-components"
import { Button } from "../../atoms/Button/Button"
import { Input } from "../../atoms/Input/Input"
import { Select } from "../../atoms/Select/Select"

export const Container = styled.div`
    width: 100%;
    min-height: 100%;
    padding: 1rem;
    display: flex;
    justify-content: center;
`

export const Content = styled.div`
    width: 100%;
    max-width: 800px;
    background: var(--primary-white-text);
    padding: 2.5rem;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
`

export const HeroTitle = styled.h2`
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    color: var(--brand-color-1);
    margin-top: 1rem;
`

export const Title = styled.h1`
    font-weight: 700;
    font-size: 56px;
    line-height: 72px;
    text-align: center;
    margin-bottom: 1rem;

    .highlight {
        color: var(--brand-color-1);
        font-weight: bold;
    }

`

export const Subtitle = styled.h3`
    font-size: 18px;
    line-height: 32px;
    color: #8195D5;
    margin-bottom: 1rem;
`

export const StyledInput = styled(Input)`
    width: 100%;
    margin-top: 2.5rem;
    background: var(--dashboard-background)
    &:first-of-type {
        margin-top: 0;
    }
`

export const SelectSection = styled.div`
    width: 100%;
    display: flex;
    gap: 1rem;
    margin-top: 2.5rem;
`

export const StyledSelect = styled(Select)`
    width: 100%;
`

export const StyledButton = styled(Button)`
    font-family: 'Montserrat', sans-serif; 
    font-weight: bold;
    width: 165px;
    margin-top: 2rem;
`

export const LoadingContainer = styled.div`
    height: calc(100vh - 122px - 2rem);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
`

export const LoadingTextAnim = keyframes`
    0% {
        opacity: 0;
        transform: translateY(100%);
    }
    100% {
        opacity: 1;
        transform: translateY(0%);
    }
`
export const LoadingTextWarning = styled.p`
    font-size: 14px;
    color: var(--brand-color-2);
`

export const LoadingText = styled.p`
    font-size: 14px;
    color: var(--brand-color-2);
    animation: ${LoadingTextAnim} 1s;
`