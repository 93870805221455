import styled from "styled-components";

export const Content = styled.div`
  width: 100%;
  max-width: 900px;
  --primary-text: var(--third-text);
`;

export const HeroText = styled.h1`
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  text-transform: uppercase;
  color: var(--brand-color-1);
`;

export const Title = styled.h2`
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;

  margin-bottom: 1rem;
  color: var(--brand-color-2);
`;
