import { useEffect, useState } from "react";
import { Loader } from "../../atoms/Loader/Loader";
import {
  Container,
  LoadingContainer,
  LoadingText,
  LoadingTextWarning,
} from "./CreateProduct.styles";
import { CreateProductContent } from "./contentstep/CreateProductContent";
import { CreateProductNameStep } from "./namestep/CreateProductNameStep";
import { CreateProductStep } from "./CreateProduct_T";
import { AetherService } from "../../services/AetherSerivce";
import { useCreateProductStore } from "../../stores/CreateProduct/CreateProductStore";
import { useNavigate } from "react-router-dom";

const randomHints = [
  "Ordering 1s and 0s...",
  "Updating dependencies...",
  "Whatever you do, don't look behind you...",
  "Please wait... Consulting the manual...",
  "It is dark. You're likely to be eaten by a grue.",
  "Loading funny message...",
  "It's 10:00pm. Do you know where your children are?",
  "Waiting Daenerys say all her titles...",
  "Feel free to spin in your chair",
  "What the what?",
  "format C: ...",
  "Forget you saw that password I just typed into the IM ...",
  "What's under there?",
  "Your computer has a virus, its name is Windows!",
  "Go ahead, hold your breath and do an ironman plank till loading complete",
  "Bored of slow loading spinner, buy more RAM!",
  "Help, I'm trapped in a loader!",
  "What is the difference btwn a hippo and a zippo? One is really heavy, the other is a little lighter",
  "Please wait, while we purge the Decepticons for you. Yes, You can thanks us later!",
  "Chuck Norris once urinated in a semi truck's gas tank as a joke....that truck is now known as Optimus Prime.",
  "Chuck Norris doesn’t wear a watch. HE decides what time it is.",
  "Mining some bitcoins...",
  "Downloading more RAM..",
  "Updating to Windows Vista...",
  "Deleting System32 folder",
  "Hiding all ;'s in your code",
  "Alt-F4 speeds things up.",
  "Initializing the initializer...",
  "When was the last time you dusted around here?",
  "Optimizing the optimizer...",
  "Last call for the data bus! All aboard!",
  "Running swag sticker detection...",
  "Never let a computer know you're in a hurry.",
  "A computer will do what you tell it to do, but that may be much different from what you had in mind.",
  "Some things man was never meant to know. For everything else, there's Google.",
  "Unix is user-friendly. It's just very selective about who its friends are.",
  "Shovelling coal into the server",
  "Pushing pixels...",
  "How about this weather, eh?",
  "Building a wall...",
  "Everything in this universe is either a potato or not a potato",
  "The severity of your issue is always lower than you expected.",
  "Updating Updater...",
  "Downloading Downloader...",
  "Debugging Debugger...",
  "Reading Terms and Conditions for you.",
  "Digested cookies being baked again.",
  "Live long and prosper.",
  "There is no cow level, but there's a goat one!",
  "Deleting all your hidden porn...",
  "Running with scissors...",
  "Definitely not a virus...",
  "You may call me Steve.",
  "You seem like a nice person...",
  "Coffee at my place, tommorow at 10A.M. - don't be late!",
  "Work, work...",
];

export const CreateProduct = () => {
  const {
    productText,
    featureText,
    benefitText,
    // feelofcopyOption,
    languageOption,
    setProductNameSuggestions,
  } = useCreateProductStore();

  const navigate = useNavigate();

  const [creationStep, setCreationStep] = useState<CreateProductStep>(
    CreateProductStep.CONTENT
  );

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hintLoadingText, setHintLoadingText] = useState<string>(
    "Packaging product information..."
  );
  const [hintLoadingSwitch, setHintLoadingSwitch] = useState<boolean>(true);

  useEffect(() => {
    if (!isLoading) return;

    const loadingTextInterval = setInterval(() => {
      setHintLoadingSwitch((prev) => !prev);
      setHintLoadingText(
        randomHints[Math.floor(Math.random() * randomHints.length)]
      );
    }, 5000);

    return () => clearInterval(loadingTextInterval);
  }, [isLoading]);

  const onClickGenerateContent = async () => {
    try {
      setIsLoading(true);

      const { data } = await AetherService.post("/product/namesuggestion", {
        product: productText,
        feature: featureText,
        benefit: benefitText,
        // feelofcopy: feelofcopyOption,
        language: languageOption,
      });

      // TODO Set the name suggestions into store
      setProductNameSuggestions(data.suggestions);
      setCreationStep(CreateProductStep.NAME);
    } catch (err) {
      // TODO Handle error
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const onClickGenerateProductDesc = async (brandName: string) => {
    try {
      setIsLoading(true);
      const brandResponse = await AetherService.post("/product", {
        name: brandName,
      });

      const brandId = brandResponse.data._id;
      console.log("Generated brandId:", brandId);

      const { data } = await AetherService.post(
        "/product-description/suggestion",
        {
          product: productText,
          feature: featureText,
          benefit: benefitText,
          // feelofcopy: feelofcopyOption,
          language: languageOption,
          productName: brandName,
        }
      );

      const productDescription = JSON.parse(data.productDescriptionChoices);

      // Save first version of the generated Description
      await AetherService.post("/product-description", {
        description: productDescription.product_description,
        productId: brandId,
      });

      navigate({
        pathname: `/dashboard/${brandId}/product-description`,
      });

      console.log("Still generating...");

      const adCopySuggestion = await AetherService.post("/ad-copy/suggestion", {
        product: productText,
        feature: featureText,
        benefit: benefitText,
        // feelofcopy: feelofcopyOption,
        language: languageOption,
        productName: brandName,
      });

      console.log({ adCopySuggestion });

      const adCopysResponse = await AetherService.post("/ad-copy", {
        text: adCopySuggestion.data.text,
        productId: brandId,
      });

      console.log({ adCopysResponse });

      const emailFlowSuggestion = await AetherService.post(
        "/email-flow/suggestion",
        {
          product: productText,
          feature: featureText,
          benefit: benefitText,
          // feelofcopy: feelofcopyOption,
          language: languageOption,
          productName: brandName,
        }
      );

      console.log({ emailFlowSuggestion });

      const emailFlowResponse = await AetherService.post("/email-flow", {
        welcome_emails: emailFlowSuggestion.data.welcome_emails,
        abandoned_checkout_emails:
          emailFlowSuggestion.data.abandoned_checkout_emails,
        post_purchase_emails: emailFlowSuggestion.data.post_purchase_emails,
        productId: brandId,
      });

      console.log({ emailFlowResponse });

      console.log("Done generating!");
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return (
      <Container>
        <LoadingContainer>
          <Loader />
          <LoadingTextWarning>
            Please, do not refresh your browser
          </LoadingTextWarning>
          {hintLoadingSwitch ? (
            <LoadingText key={"FL1"}>{hintLoadingText}</LoadingText>
          ) : (
            <LoadingText key={"FL2"}>{hintLoadingText}</LoadingText>
          )}
        </LoadingContainer>
      </Container>
    );
  }

  switch (creationStep) {
    case CreateProductStep.CONTENT:
      return <CreateProductContent onClickGenerate={onClickGenerateContent} />;

    case CreateProductStep.NAME:
      return (
        <CreateProductNameStep onClickGenerate={onClickGenerateProductDesc} />
      );

    default:
      return null;
  }
};
