import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Chevron } from "../../icons/Chevron";
import { Logout } from "../../icons/Logout";
import { Dollar } from "../../icons/Dollar";
import { User } from "../../icons/User";
import { AetherService } from "../../services/AetherSerivce";
import { useAuthStore } from "../../stores/Authentication/AuthStore";
import { useUserStore } from "../../stores/User/UserStore";
import {
  WhiteBrandroidLogo,
  Container,
  DropdownRow,
  Nav,
  NavigationSection,
  NavItem,
  UserContainer,
  UserDropdownContainer,
  UserDropdownSection,
  UserDropdownSubText,
  UserDropdownText,
  UserDropdownTopSection,
  Username,
  UserSectionDivider,
} from "./TopNavigation.styles";
import "react-toastify/dist/ReactToastify.css";

export const TopNavigation: React.FC = () => {
  const navigate = useNavigate();
  const { clearAuth } = useAuthStore();
  const { user } = useUserStore();
  const [isLoggingOut, setIsLoggingOut] = useState<boolean>(false);

  const [openUserOption, setUserOptionOpen] = useState<boolean>(false);

  const onClickBrand = async () => {
    const { data } = await AetherService.get("/user/product/all");

    if (data.length > 0) {
      // Assuming you have the necessary ID and feature values available
      const id = data.reverse()[0]._id;

      navigate(`/dashboard/${id}/product-description`);
    } else {
      navigate("/dashboard/create");
    }
  };

  const onLogout = async (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();

    if (isLoggingOut) return;

    try {
      setIsLoggingOut(true);
      await AetherService.post("/auth/logout");
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoggingOut(false);
      clearAuth();
      navigate("/");
    }
  };

  //   const handleUpgradeClick = (e: any) => {
  //     e.preventDefault(); // Prevents the default link behavior
  //     toast.warn("Payment plans are coming soon!", {
  //       position: toast.POSITION.TOP_CENTER,
  //     });
  //   };

  const handleBillingClick = () => {
    navigate({ pathname: "/dashboard/billing" });
  };

  return (
    <Container>
      <Link to="/dashboard">
        <WhiteBrandroidLogo
          src={`${process.env.PUBLIC_URL}/img/brandroid_logo.svg`}
          alt="brandroid logo"
          onClick={onClickBrand}
        />
      </Link>

      {/* TODO: No upgrade button until we either allow Monthly to yearly upgrade OR more/new plans */}
      {/* {user.email === "liam.bergstroms@icloud.com" ? (
                    <Link to="/dashboard/billing">
                        <NavItem><UpgradeButton>Upgrade</UpgradeButton></NavItem>
                    </Link>
                ) : (
                    <a href="#" onClick={handleUpgradeClick}>
                        <NavItem><UpgradeButton>Upgrade</UpgradeButton></NavItem>
                    </a>
                )} */}

      <NavigationSection>
        <Nav>
          <Link
            to="/dashboard/create"
            style={{
              textDecoration: "none",
            }}
          >
            <NavItem
              style={{
                lineHeight: "normal",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: 16,
              }}
            >
              Create new Brand
            </NavItem>
          </Link>

          <Link to={`/dashboard`} style={{ textDecoration: "none" }}>
            <NavItem>Your Brands</NavItem>
          </Link>

          <a
            href="https://www.brandroid.ai/help-and-tutorials"
            target="_blank"
            style={{ textDecoration: "none" }}
          >
            <NavItem>Help & tutorials</NavItem>
          </a>
        </Nav>
      </NavigationSection>

      <UserContainer onClick={() => setUserOptionOpen((prev) => !prev)}>
        <User color="#fff" />
        <Username>{user.firstname}</Username>
        <Chevron color="#fff" size="16" />

        {openUserOption && (
          <UserDropdownContainer>
            <UserDropdownTopSection>
              <UserDropdownText style={{ color: "var(--brand-color-2)" }}>
                {user.firstname}
              </UserDropdownText>
              <UserDropdownSubText>{user.email}</UserDropdownSubText>
            </UserDropdownTopSection>

            <UserSectionDivider />

            <UserDropdownSection>
              {/* <DropdownRow>
                                    <UserHalf size="16" color="var(--brand-color-2)" />
                                    <UserDropdownText>View profile</UserDropdownText>
                                </DropdownRow>
                                <DropdownRow>
                                    <Settings size="16" color="var(--brand-color-2)" />
                                    <UserDropdownText>Settings</UserDropdownText>
                                </DropdownRow> */}
              {user.email === "liam.bergstroms@icloud.com" ? (
                <Link
                  to="/dashboard/billing"
                  style={{
                    textDecoration: "none",
                    color: "var(--brand-color-2)",
                  }}
                >
                  <DropdownRow>
                    <Dollar size="16" />
                    <UserDropdownText>Billing</UserDropdownText>
                  </DropdownRow>
                </Link>
              ) : (
                <a
                  href="#"
                  onClick={handleBillingClick}
                  style={{
                    textDecoration: "none",
                    color: "var(--brand-color-2)",
                  }}
                >
                  <DropdownRow>
                    <Dollar size="16" />
                    <UserDropdownText>Billing</UserDropdownText>
                  </DropdownRow>
                </a>
              )}
            </UserDropdownSection>

            <UserSectionDivider />

            <UserDropdownSection>
              <DropdownRow onClick={(e) => onLogout(e)}>
                <Logout size="16" color="var(--brand-color-1)" />
                <UserDropdownText>Logout</UserDropdownText>
              </DropdownRow>
            </UserDropdownSection>
          </UserDropdownContainer>
        )}
      </UserContainer>
    </Container>
  );
};
