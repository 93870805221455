import axios from "axios"
import useStore from "../stores/Authentication/AuthStore";

// Access the environment variable using 'process.env'
const environment = process.env.REACT_APP_ENVIRONMENT;

// Now, you can use the 'environment' variable in your code
let backendBaseUrl = "";
if (environment === 'prod') {
    backendBaseUrl = "https://backend.brandroid.ai"
} else {
    backendBaseUrl = "http://localhost:80"
}



export const AetherService = axios.create({
    baseURL: backendBaseUrl,
    timeout: 120000,
    headers: {
        "Content-Type": "application/json",
    },
    withCredentials: true
})

AetherService.interceptors.request.use(config => {

    const accessToken = useStore.getState().accessToken; // Access the accessToken directly from the store

    if (accessToken) {
        config.headers['X-Access-Token'] = accessToken;
    }
    return config;
}, error => {
    return Promise.reject(error);
});

AetherService.interceptors.response.use((response) => {
    return response
},
    (error) => {
        // LOCALSTORAGE NOT ALLOWED IN SHOPIFY TERMS:
        //    if (error.response?.data?.errorHandleCode === "AT401") {
        //         localStorage.removeItem("isAuth")
        //     }

        return Promise.reject(error)
    })