import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AetherService } from "../../services/AetherSerivce";
import { useBrandStoreStore } from "../../stores/Brands/BrandsStore";
import { Content, HeroText, Title } from "./AdCopy.styles";
import "react-edit-text/dist/index.css";
import { ToastContainer, toast } from "react-toastify";
import {
  Container,
  StyledEditTextarea,
} from "../productdescription/ProductDescription.styles";

import ToastCustomIcon from "../../icons/ToastCustomIcon";
import { ToastCustomMessage } from "../../atoms/ToastCustomMessage/ToastCustomMessage";
import { CloseIcon } from "../../icons/CloseIcon";

export const AdCopy = () => {
  const { id } = useParams();
  const { getBrandName } = useBrandStoreStore();
  const [isEditing, setIsEditing] = useState(false);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [adCopy, setAdCopy] = useState<string>("");
  const [adCopyId, setAdCopyId] = useState<string>("");

  const editTextStyle = {
    minHeight: "55vh",
    maxHeight: "65vh",
    fontSize: "16px",
    lineHeight: "24px",
    borderRadius: "6px",
  };

  const handleSave = async ({ value }: any) => {
    try {
      await AetherService.put("/ad-copy/" + adCopyId, {
        //byt till rätt id
        text: value,
      });
      toast.success(<ToastCustomMessage message="Saved your changes!" />, {
        className: "custom-toast-success",
        icon: <ToastCustomIcon />,
        closeButton: (
          <div style={{ margin: 0 }}>
            <CloseIcon size="12" color="var(--brand-color-1)" />
          </div>
        ),
      });
    } catch (e) {
      toast.error(
        "Failed to save your changes. Please copy and paste your text to another document."
      );
      console.error(e);
    }
  };

  useEffect(() => {
    const getAdCopy = async () => {
      try {
        const { data } = await AetherService.get(`/product/ad-copy/${id}`);

        if (data && data.length > 0) {
          const adCopyData = data[0];
          console.log(adCopyData);
          setAdCopy(adCopyData.text);
          setAdCopyId(adCopyData._id);
        }
      } catch (err) {
        console.log(err);
      } finally {
        setIsLoading(false);
      }
    };

    getAdCopy();
  }, [id]);

  if (!id) return null;
  if (isLoading) return null;

  if (!adCopyId) return null;
  return (
    <Container>
      <Content>
        <HeroText>{getBrandName(id) || ""}</HeroText>
        <Title>Ad Copy Example</Title>

        <StyledEditTextarea
          rows={20}
          onSave={handleSave}
          value={adCopy}
          onChange={(e) => setAdCopy(e.target.value)}
          onEditMode={() => setIsEditing(true)}
          onBlur={() => setIsEditing(false)}
          isEditing={isEditing}
          style={editTextStyle} // Pass the isEditing state as a prop
        />
      </Content>
      <ToastContainer />
    </Container>
  );
};
