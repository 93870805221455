import { createTrackedSelector } from "react-tracked";
import { create } from "zustand";
import { UserStoreState } from "../../types/User_T";

export type UserStore_T = {
  user: UserStoreState;
  setUser: (user: UserStoreState) => void;
};

const useStore = create<UserStore_T>((set) => ({
  user: { email: "", firstname: "" },
  setUser: (user: UserStoreState) => set({ user }),
}));

export const useUserStore = createTrackedSelector(useStore);
