import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import SuccessSVG from "../../icons/Success";
import {
  Container,
  SVGContainer,
  Message,
  Instructions,
  StyledButton,
} from "./SuccessPage.styles";
import { AetherService } from "../../services/AetherSerivce";

const SuccessPage: React.FC = () => {
  const navigate = useNavigate();
  const [hasAlreadyGotBrands, setHasAlreadyGotBrands] = useState<boolean>();

  async function initSuccessPage() {
    const { data } = await AetherService.get("/user/product/all");

    if (data.length > 0) {
      setHasAlreadyGotBrands(true);
    } else {
      setHasAlreadyGotBrands(false);
    }
  }
  useEffect(() => {
    initSuccessPage();
  });

  const handleButtonClick = () => {
    if (hasAlreadyGotBrands) {
      navigate("/dashboard");
    } else {
      navigate({
        pathname: "/dashboard/create",
      });
    }
  };

  return (
    <Container>
      <SVGContainer>
        <SuccessSVG />
      </SVGContainer>
      {hasAlreadyGotBrands ? (
        <>
          <Message>You're now resubscribed to Brandroid!</Message>
          <Instructions>
            Congratulations! You're one step closer to scaling your brands!
          </Instructions>
          <StyledButton onClick={handleButtonClick}>
            To your dashboard
          </StyledButton>
        </>
      ) : (
        <>
          <Message>You're now ready for Brandroid!</Message>
          <Instructions>
            Take the next step by creating your first brand...
          </Instructions>
          <StyledButton onClick={handleButtonClick}>
            Create your first Brand
          </StyledButton>
        </>
      )}
    </Container>
  );
};

export default SuccessPage;
