import { createTrackedSelector } from "react-tracked"
import { create } from "zustand"


export type BrandStore_T = {
    brands: { [key: string]: string }
    addBrand: (brandId: string, brandName: string) => void
    removeBrand: (brandId: any) => void
    getBrandName: (brandId: string) => string
}

const useStore = create<BrandStore_T>((set, get) => ({
    brands: {},

    addBrand: (brandId: string, brandName: string) => set({ brands: { ...get().brands, [brandId]: brandName } }),
    removeBrand: (brandId: any) => {
        const { brands } = get();
        delete brands[brandId];
        set({ brands: { ...brands } });
    },

    getBrandName: (brandId: string) => get().brands[brandId]
}))

export const useBrandStoreStore = createTrackedSelector(useStore)