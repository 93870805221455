export const WarningIcon = () => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 6H32L42 17V31L32 42H16L6 31V17L16 6Z"
        fill="#FF0000"
        stroke="#FF0000"
        strokeWidth={4}
      />
      <path d="M24 28L24 14M24 30L24 34" stroke="white" strokeWidth={4} />
    </svg>
  );
};
