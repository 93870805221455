import { useEffect } from "react";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { CreateProduct } from "./pages/createproduct/CreateProduct";
import { Dashboard } from "./pages/dashboard/Dashboard";
import { Login } from "./pages/login/Login";
import { ProductDescription } from "./pages/productdescription/ProductDescription";
import { AdCopy } from "./pages/adcopy/AdCopy";
import { EmailFlow } from "./pages/emailflow/EmailFlow";
import { Analytics } from "./pages/analytics/Analytics";
import { Register } from "./pages/register/Register";
import { Billing } from "./pages/billing/Billing";
import { DashboardSideNav } from "./wrappers/DashboardSideNav/DashboardSideNav";
import { DashboardWrapper } from "./wrappers/DashboardWrapper";
import SuccessPage from "./pages/payments/SuccessPage";
import FailedPage from "./pages/payments/FailedPage";
import Payment from "./pages/payments/Payment";
import { ForgotPassword } from "./pages/forgot-password/ForgotPassword";
import { ChangePassword } from "./pages/change-password/ChangePassword";

const App = () => {
  useEffect(() => {
    // Access the environment variable using 'process.env'
    const environment = process.env.REACT_APP_ENVIRONMENT;

    // Now, you can use the 'environment' variable in your code
    if (environment === "dev") {
      console.log("Running in development environment.");
    } else if (environment === "prod") {
      console.log("Running in production environment.");
    } else {
      console.log("Unknown environment.");
    }
  }, []);

  return (
    <Router>
      <Routes>
        <Route path="" element={<Login />} />
        <Route path="register" element={<Register />} />
        <Route path="forgot-password" element={<ForgotPassword />} />
        <Route path="change-password/:id" element={<ChangePassword />} />

        <Route element={<DashboardWrapper />}>
          <Route path="dashboard">
            <Route element={<DashboardSideNav />}>
              <Route path="" element={<Dashboard />} />
              <Route
                path=":id/product-description"
                element={<ProductDescription />}
              />
              <Route path=":id/ad-copy" element={<AdCopy />} />
              <Route path=":id/email-flow" element={<EmailFlow />} />
              <Route path=":id/analytics" element={<Analytics />} />
            </Route>

            <Route path="payment" element={<Payment />} />
            <Route path="billing" element={<Billing />} />
            <Route path="success" element={<SuccessPage />} />
            <Route path="failed" element={<FailedPage />} />
            <Route path="create" element={<CreateProduct />} />
          </Route>
        </Route>
      </Routes>
    </Router>
  );
};

export default App;
