import styled from "styled-components"

export const Container = styled.div`
    position: relative;
`

export const StyledInput = styled.input`
    width: 100%;
    line-height: 24px;
    font-size: 14px;
    font-weight: 400;
    padding: 12px 16px 12px 16px;
    border: solid 1px var(--dashboard-background);
    background:  var(--dashboard-background);
    border-radius: 6px;
    outline: none;

    &::placeholder {
        color: #8195D5;
    }

    &[data-haserror="true"] {
        border-color: #ef4444;
    }
`

export const StyledLabel = styled.p`
    position: absolute;
    bottom: calc(100% + 0.5rem);
    font-weight: 400;
    font-size: 14px;
`

export const StyledSubLabel = styled.p`
    position: absolute;
    bottom: calc(100% + 1.8rem);
    font-weight: 500;
    font-size: 12px;
    color: #333;
`
